//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { BaseModel } from "./bases/base.model";
import { DiscountGalleryModel } from "./discountgallery.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DiscountModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Eka Saputra.
		Created on : Wednesday, 30 March 2022. 			Updated on : Wednesday, 30 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	BRINSUpdateID?: number;

	Code?: string;
	Description?: string;
	TermAndCondition?: string;
	StartOn?: Date; // ActivedOn
	ExpiryOn?: Date; // ExpiredOn

	Name?: string;
	BranchCode?: string;
	CoverageCode?: string;
	ProductCode?: string;
	Percentage?: string;
	Amount?: string;
	MinimumPremium?: string;
	MaximumPremium?: string;
	MinimumPercentage?: string; // dalam bentuk amount
	MaximumPercentage?: string; // dalam bentuk amount
	Platform?: string;
	Currency?: string;

	listModelDiscountGallery?: Array<DiscountGalleryModel>;


	/* Attribute - END */

	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.listModelDiscountGallery = [];
	}

	//#endregion


	//#region VALIDATION


	validateforCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Promo";

		if(this.Code == null || this.Code === undefined || this.Code === "")
		{
			modelResponse.MessageContent = "Kode promo tidak boleh kosong.";
		}
		else if(this.Code.length <= NumberConstant.NUMBER_COMPARE_VALUE_FOUR)
		{
			modelResponse.MessageContent = "Kode promo harus lebih dari "+NumberConstant.NUMBER_COMPARE_VALUE_FOUR+" digit.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}
		return modelResponse;
	}

	validateforCoverageCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Covered Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforProductCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Product Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateCheckForDiscount(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateforProductCode();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforCoverageCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforCode();
		}
		else
		{

		}

		modelResponse.MessageTitle = "Promo.";
		return modelResponse;
	}

	//#endregion


	//#region SET PRODUCT

	setProduct(stringProductCode: string, stringProductName: string, stringCoverageCode: string): void
	{
		this.ProductCode = stringProductCode;
		this.ProductName = stringProductName;
		this.CoverageCode = stringCoverageCode;
	}

	setPromoCodeNotValid(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Promo.";
		modelResponse.MessageContent = "Kode promo tidak valid, mohon masukan kode promo dengan benar.";
		return modelResponse;
	}
	//#endregion

}

//#endregion
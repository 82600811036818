
<div class="DivContainerCapture">
	<webcam *ngIf="!error"
		[height]="500"
		[width]="500"
		[trigger]="imageTrigger"
		(imageCapture)="captureImage($event)" style="margin-top: 50px">
	</webcam>
	<div style.background-image="url('{{_backgroundImageURL}}')" class="DivFrameImage"></div>
	<div mat-dialog-actions>
	<div class="DivContainerButton">
		<span mat-button mat-dialog-close>Cancel</span>
		<button mat-raised-button class="ButtonCapture" color="primary" (click)="triggerSnapshot()" [disabled]="!!error"></button>
	</div>
	</div>
</div>
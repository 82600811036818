//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductAsriModel } from "../models/productasri.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { PremiumModel } from "../models/premium.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductasriService extends BaseService
{

	//#region CONSTRUCTOR

	public _modelProductAsri: Observable<ProductAsriModel>;
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelPremium: Observable<PremiumModel>;

	public _boolanProduct: Observable<boolean>;

	private _behaviourSubjectProductAsriModel: BehaviorSubject<ProductAsriModel>;
	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectPremiumModel: BehaviorSubject<PremiumModel>;

	private _behaviourBooleanProduct: BehaviorSubject<boolean>;

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductAsriModel = new BehaviorSubject(new ProductAsriModel());
		this._modelProductAsri = this._behaviourSubjectProductAsriModel.asObservable();

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectPremiumModel = new BehaviorSubject(new PremiumModel());
		this._modelPremium = this._behaviourSubjectPremiumModel.asObservable();

		this._behaviourBooleanProduct = new BehaviorSubject<boolean>(false);
		this._boolanProduct = this._behaviourBooleanProduct.asObservable();
	}

	setProductCode(modelProductAsri: ProductAsriModel)
	{
		this._behaviourSubjectProductAsriModel.next(modelProductAsri);
	}

	setBooleanProduct(booleanProduct: boolean)
	{
		this._behaviourBooleanProduct.next(booleanProduct);
	}

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductGeneralPolicy(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelPremium(modelPemium: PremiumModel)
	{
		this._behaviourSubjectPremiumModel.next(modelPemium);
	}

	// setModeProductAsri(model)

	//#endregion


	//#region SELECT

	calculatePremiumAsriForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SELECTCALCULATEPREMIUMASRIFORNEWDELIVERYSYSTEM);
	}

	insertProductAsriForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_INSERTPRODUCTASRIFORNEWDELIVERYSYSTEM);
	}

	updateInsuredForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_UPDATEINSUREDASRIFORNEWDELIVERYSYSTEM);
	}

	uplodaProductAsriForBRIMO(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORNEWDELIVERYSYSTEM);
	}

	uplodaProductAsriForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORWEBSITECUSTOMER);
	}

	saveProductAsriForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SAVEPRODUCTASRIFORNEWDELIVERYSYSTEM);
	}

	sendSPPAByEmailAsriForBRIMO(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SENDSPPABYEMAILASRIFORNEWDELIVERYSYSTEM);
	}

	confirmPaymentForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCTASRI_CONFIRMPAYMENTASRIFORNEWDELIVERYSYSTEM);
	}

	generatePaymentForBRIMO(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERAPAYMENTBRIVA);
	}

	sharePayementForBRIMO(interfaceGeneralService: GeneralServiceInterface, modelProducGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProducGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SHAREPAYMENTFORNEWDELIVERYSYSTEM);
	}

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM);
	}

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCT_ASRICONFIRMPAYMENT);
	}

	//#endregion

}

//#endregion

<div id="divBodyDashboard" class="DivBodyGeneral">
	<div id="divMainDashboard">
		<div class="DivBackgroundProduct">
			<img class="ImgIconAsri">
			<p>BRINS ASRI</p>
		</div>

		<h2>Deskripsi</h2>
		<h4>Melindungi rumah tinggal dan properti yang ada di dalamnya    dari   kehilangan dan kerusakan yang disebabkan oleh risiko yang tidak terduga (yaitu: kebakaran, petir, ledakan, kejatuhan pesawat terbang, asap dan perluasannya).</h4>

		<h2>Harga Pertanggungan</h2>
		<div class="DivPriceValidation MarginTop10">
			<ul>
				<li>Harga Pertanggungan Maksimal s.d. Rp3.000.000.000</li>
				<li>Harga Perlengkapan Minimum Rp100.000</li>
				<li>Harga Perlengkapan Maksimum ≥ 10% Total Harga Bangunan</li>
			</ul>
		</div>

		<h2>Pilih Paket</h2>

		<div class="DivSelectPackage">
			<div class="DivWrapperSelectedPackage" (click)="openPackageListDialog()">
				<div class="ContainerLabelPackage">
					<ng-container *ngIf="this._modelProductAsri.modelCoverage === undefined || this._modelProductAsri.modelCoverage === null">
						<span>Paket</span>
						<label>Pilih Paket</label>
					</ng-container>

					<ng-container *ngIf="this._modelProductAsri.modelCoverage !== undefined && this._modelProductAsri.modelCoverage !== null">
						<div class="DivIconPackage">
							<img src="{{this._modelProductAsri.modelCoverage.IconURL}}">
							<div class="DivContainerLabelPackage">
								<span>Paket</span>
								<label>{{this._modelProductAsri.modelCoverage.Text}}</label>
							</div>
						</div>
					</ng-container>
				</div>
				<img src="../../../../../assets/inputs/sakina/input_arrow_bottom.svg">
			</div>
		</div>

		<ng-container *ngIf="this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM">
			<div class="DivContentPackage">
				<hr>
				<h4>Berikut adalah risiko yang dijamin:</h4>
				
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck">
						<label style="margin-left: 30px;" id="LabelForTitle">Kebakaran, Petir, Ledakan, Kejatuhan Pesawat Terbang, Asap</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Tanggung Jawab Hukum Pihak Ketiga</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Banjir, Angin Topan, Badai, Kerusakan Akibat Air</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label class="LabelMarginLeft20" id="LabelForTitle">Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang Tercantum Dalam Polis (RSMDCC)</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Terorisme dan Sabotase</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Kebongkaran</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Santunan Duka Meninggal Dunia dan Cacat Tetap Akibat Kecelakaan</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Santunan Biaya Pengobatan atau Perawatan Medis</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD || this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Bantuan Sewa Rumah Tinggal</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRISILVER ||  this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD , 'ImgCheck': this._modelProductAsri.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM}">
						<label id="LabelForTitle">Gempa Bumi, Tsunami, Letusan Gunung Berapi</label>
				</div>
			</div>
		</ng-container>

		<div class="DivWrapperListFormCard MarginLeft15 MarginRight15">
			<div class="DivCardInformation DivCardInformationForPackage">
				<div class="DivFirstCardPackage">
					<div class="DivWrapImgInformation">
						<img class="ImgInformation">
						<p>Informasi</p>
					</div>
					<label>Asuransi BRINS ASRI adalah produk asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia. Produk ini bukan merupakan produk dari PT. Bank Rakyat Indonesia (Persero), Tbk serta tidak termasuk dalam program penjaminan Pemerintah Republik Indonesia. PT. Bank Rakyat Indonesia (Persero) Tbk tidak bertanggung jawab atas kepesertaan asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia sehubungan dengan produk Asuransi BRINS ASRI. PT. Bank Rakyat Indonesia (Persero), Tbk bukan agen PT. BRI Asuransi Indonesia maupun broker dari nasabah PT. Bank Rakyat Indonesia (Persero), Tbk. PT. BRI Asuransi Indonesia adalah perusahaan asuransi yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</label>
				</div>
			</div>
		</div>
		<input type="button" class="ButtonNext" value="Lanjutkan" [disabled]="_booleanButtonDisable" (click)="goToPremiumSimulation()">
	</div>
</div>


<!-- START MODAL -->

<div class="DivModal" id="divModalBottomListItem">
	<label class="LabelPackageList">Paket</label>
	<form>
		<ng-container *ngFor="let modalItemBottom of _arrayDisplayed; let numberIndex = index">
			<div class="DivListItemForDropdown">
				<div class="DivContainerIconPackage">
					<input type="button" value="{{ modalItemBottom }}" name="modalDialogBottom"
						id="modalBottomItem{{numberIndex}}" (click)="closeModalBottomList(modalItemBottom)"
						[value]="modalItemBottom.PackageCode">
					<img src="{{modalItemBottom.IconURL}}">
					<label class="labelForPackage" for="modalBottomItem{{numberIndex}}">{{ modalItemBottom.Text }}</label>
				</div>
			</div>
			<hr>
		</ng-container>
	</form>
</div>

<!-- END MODAL -->
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { PremiumModel } from "../models/premium.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductMyMotorcycleModel } from "../models/productmymotorcycle.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductMymotorcycleService extends BaseService
{

	//#region DECLARATION

	public _modelProductMyMotorcycle: Observable<ProductMyMotorcycleModel>;
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelPremium: Observable<PremiumModel>;

	public _boolanProduct: Observable<boolean>;

	private _behaviourSubjectProductMyMotorcycleModel: BehaviorSubject<ProductMyMotorcycleModel>;
	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectPremiumModel: BehaviorSubject<PremiumModel>;

	private _behaviourBooleanProduct: BehaviorSubject<boolean>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductMyMotorcycleModel = new BehaviorSubject(new ProductMyMotorcycleModel());
		this._modelProductMyMotorcycle = this._behaviourSubjectProductMyMotorcycleModel.asObservable();

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectPremiumModel = new BehaviorSubject(new PremiumModel());
		this._modelPremium = this._behaviourSubjectPremiumModel.asObservable();

		this._behaviourBooleanProduct = new BehaviorSubject<boolean>(false);
		this._boolanProduct = this._behaviourBooleanProduct.asObservable();
	}

	//#endregion


	//#region SETTER

	setProductCode(modelProductMyMotorcycle: ProductMyMotorcycleModel)
	{
		this._behaviourSubjectProductMyMotorcycleModel.next(modelProductMyMotorcycle);
	}

	setBooleanProduct(booleanProduct: boolean)
	{
		this._behaviourBooleanProduct.next(booleanProduct);
	}

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductGeneralPolicy(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelPremium(modelPemium: PremiumModel)
	{
		this._behaviourSubjectPremiumModel.next(modelPemium);
	}


	//#endregion


	//#region CALCULATE

	calculatePremiumForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_CALCULATEPREMIUMFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region INSERT

	insertProductForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_INSERTPRODUCTFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region UPDATE

	updateInsuredForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEINSUREDFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region UPLOAD

	uploadProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region SELECT

	selectProductByTokenForNewDeliverSystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM);

	}

	//#endregion


	//#region SAVE

	saveProductForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SAVEPRODUCTFORNEWDELIVERYSYSTEM);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SENDSPPABYEMAILFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_CONFIRMPAYMENT);

	}

	sharePaymentForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SHAREPAYMENTFORNEWDELIVERYSYSTEM);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBRIVA);
	}

	//#endregion

}

//#endregion

//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForNewDeliverySystem`;
	static STRING_URL_HANDSHAKE_SELECTHANDSHAKEBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectHandshakeByTokenForNewDeliverySystem`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTDASHBOARDLETTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardLetter`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;
	static STRING_URL_CUSTOMER_SELECTUSEREMAILFORBRIMO: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectUserByEmailForBRIMO`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByAttributes`;

	//#endregion


	//#region DECLARATION

	static STRING_PATH_CONTROLLER_DECLARATION: string = "/Declaration";

	static STRING_URL_DECLARATION_INSERTDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/insertDeclaration`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForMaker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForChecker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForSigner`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByToken`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationQRByToken`;
	static STRING_URL_DECLARATION_DELETEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/deleteDeclarationByToken`;
	static STRING_URL_DECLARATION_UPDATEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/updateDeclarationByToken`;
	static STRING_URL_DECLARATION_REJECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/rejectDeclarationByToken`;
	static STRING_URL_DECLARATION_CHECKDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/checkDeclarationByToken`;
	static STRING_URL_DECLARATION_SIGNDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/signDeclarationByToken`;
	static STRING_URL_DECLARATION_UPLOADDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/uploadDeclaration`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringTicketDocumentToken=[DocumentToken]`;

	//#endregion


	//#region SUBMISSION

	static STRING_PATH_CONTROLLER_SUBMISSION: string = "/Submission";

	static STRING_URL_SUBMISSION_INSERTSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/insertSubmission`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForMaker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForChecker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForSigner`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByToken`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionQRByToken`;
	static STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/deleteSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionByToken`;
	static STRING_URL_SUBMISSION_REJECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/rejectSubmissionByToken`;
	static STRING_URL_SUBMISSION_CHECKSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/checkSubmissionByToken`;
	static STRING_URL_SUBMISSION_SIGNSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/signSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/uploadSubmission`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByAttributes`;
	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_INSERTBANKACCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/insertBankAccount`;
	static STRING_URL_BANKACCOUNT_UPDATEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/updateBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_DELETEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/deleteBankAccountByToken`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCTASRI_SELECTCALCULATEPREMIUMASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_INSERTPRODUCTASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_UPDATEINSUREDASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_SAVEPRODUCTASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_SENDSPPABYEMAILASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_CONFIRMPAYMENTASRIFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmPaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectProductByTokenForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_GENERAPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTASRI_SHAREPAYMENTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTASRI_UPLOADPRODUCTASRIFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCT_ASRICONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmPayment`;

	//#endregion


	//#region PRODUCT GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALREFERANCENUMBERBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductGeneralReferenceNumberByToken`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCTOTO_SELECTCALCULATEPREMIUMOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_INSERTPRODUCTOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_UPDATEINSUREDOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_UPLOADPRODUCTOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_SAVEPRODUCTOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_SENDSPPABYEMAILOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_CONFIRMPAYMENTOTOFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/confirmPaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTFORBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTOTO_SHAREPAYMENTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTOTO_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectProductByTokenForNewDeliverySystem`;
	static STRING_URL_PRODUCT_OTOCONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/confirmPayment`;

	//#endregion


	//#region PRODUCT BICYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTBICYCLE: string = "/ProductBicycle";

	static STRING_URL_PRODUCTBICYCLE_SELECTCALCULATEPREMIUMBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_INSERTPRODUCTBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_UPDATEINSUREDBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_SAVEPRODUCTBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_SENDSPPABYEMAILBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_CONFIRMPAYMENTBICYCLEFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/confirmPayment`;
	static STRING_URL_PRODUCTBICYCLE_GENERATEPAYMENTFORBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTBICYCLE_SHAREPAYMENTFORBRIMO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTBICYCLE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/selectProductByTokenForNewDeliverySystem`;
	static STRING_URL_PRODUCT_BICYCLECONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/confirmPayment`;

	//#endregion


	//#region PRODUCT ASMIK DAMAGE OF BUSINESS PLACE (KTU)

	static STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE: string = "/ProductDamageOfBusinessPlace";

	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_CALCULATEPREMIUMFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_INSERTPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPDATEINSUREDFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SAVEPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SENDSPPABYEMAILFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SHAREPAYMENTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/confirmPayment`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/selectProductByTokenForNewDeliverySystem`;

	//#endregion


	//#region PRODUCT MY HOUSE (RUMAHKU)

	static STRING_PATH_CONTROLLER_PRODUCTMYHOUSE: string = "/ProductMyHouse";

	static STRING_URL_PRODUCTMYHOUSE_CALCULATEPREMIUMFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_INSERTPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_UPDATEINSUREDFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_SAVEPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_SENDSPPABYEMAILFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTMYHOUSE_SHAREPAYMENTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYHOUSE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/confirmPayment`;
	static STRING_URL_PRODUCTMYHOUSE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/selectProductByTokenForNewDeliverySystem`;

	//#endregion


	//#region PRODUCT MY MOTORCYCLE (MOTORKU)

	static STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE: string = "/ProductMyMotorcycle";

	static STRING_URL_PRODUCTMYMOTORCYCLE_CALCULATEPREMIUMFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/calculatePremiumForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_INSERTPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/insertProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEINSUREDFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/updateInsuredForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/uploadProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SAVEPRODUCTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/saveProductForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SENDSPPABYEMAILFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/sendSPPAByEmailForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SHAREPAYMENTFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/sharePaymentForNewDeliverySystem`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/confirmPayment`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/selectProductByTokenForNewDeliverySystem`;

	//#endregion


	//#region VEHICLE BRAND & VEHICLE BRAND MOTORCYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrand`;


	static STRING_PATH_CONTROLLER_VEHICLEBRANDMOTORCYCLE: string = "/VehicleBrandMotorcycle";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRANDMOTORCYCLEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRANDMOTORCYCLE}/selectVehicleBrandMotorcycleForCustomer`;

	//#endregion


	//#region VEHICLE MODEL & VEHICLE MODEL MOTORCYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrand`;


	static STRING_PATH_CONTROLLER_VEHICLEMODELMOTORCYCLE: string = "/VehicleModelMotorcycle";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELMOTORCYCLEBYVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODELMOTORCYCLE}/selectVehicleModelMotorcycleByVehicleBrandForCustomer`;

	//#endregion


	//#region VEHICLE SUBMODEL & VEHICLE SUBMODEL MOTORCYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLESUBMODEL: string = "/VehicleSubModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLESUBMODEL: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLESUBMODEL}/selectVehicleSubModelByVehicleModelForCustomer`;


	static STRING_PATH_CONTROLLER_VEHICLESUBMODELMOTORCYCLE: string = "/VehicleSubModelMotorcycle";

	static STRING_URL_VEHICLESUBMODEL_SELECTVEHICLESUBMODELMOTORCYCLEBYVEHICLEMODELFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLESUBMODELMOTORCYCLE}/selectVehicleSubModelMotorcycleByVehicleModelForCustomer`;

	//#endregion


	//#region VEHICLE LOCATION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForCustomer`;

	//#endregion


	//#region VEHICLE MANUFACTURE YEAR  CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR: string = "/VehicleManufactureYear";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMANUFACTUREYEAR: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR}/selectVehicleManufactureYearForCustomer`;

	//#endregion


	//#region VEHICLE REGION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEREGION: string = "/VehicleRegion";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEREGION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEREGION}/selectVehicleRegionForCustomer`;

	//#endregion


	//#region VEHICLE COLOR CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLCOLOR: string = "/VehicleColor";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLECOLOR: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLCOLOR}/SelectVehicleColorForCustomer`;


	//#region VEHICLE FUNCTION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEFUNCTION: string = "/VehicleFunction";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEFUNCTION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEFUNCTION}/selectVehicleFunctionForCustomer`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";

	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCodeForBRIMO`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_LICENCEPLATEPREFIX: string = "/LicensePlatePrefix";

	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENCEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENCEPLATEPREFIX}/selectLicensePlatePrefix`;

	//#endregion


	//#region DISCOUNT

	static STRING_PATH_CONTROLLER_DISCOUNT: string = "/Discount";
	static STRING_URL_DISCOUNT_VERIFYDISCOUNTCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/verifyDiscountCode`;

	//#endregion


	//#region RELATIONSHIP CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIP: string = "/Relationship";

	static STRING_URL_RELATIONSHIP_SELECTRELATIONSHIPFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIP}/selectRelationshipForCustomer`;

	//#endregion
}

//#endregion
//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { CoverageModel } from "./coverage.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductAsriModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asri model, used for SPPA and policy.
		Author: Ibrahim Aziz.
		Created on : Monday, 19 October 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:6.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	Policy?: string;
	ProductCode?: string;

	Disclaimer?: boolean;
	SurveyAsriID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* POLICYHOLEDR - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalName?: string;
	BuildingPostalCode?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	BuildingPrice?: number;
	InteriorPrice?: number;
	numbereriorPrice?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;
	BooleanPolcyholderAddress?: boolean;

	Construction?: string;
	RiskOccupation?: number;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;
	Status?: ENUM_SPPA_STATUS;

	/* PREMIUM - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductAsriGallery?: Array<ProductBaseGalleryModel>;
	modelProductAsriPayment?: ProductBaseGalleryModel;
	modelCoverage?: CoverageModel;

	booleanDisable?: boolean;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelProductAsriGallery = [];
	}

	//#endregion


	//#region VALIDATE

	validateForCoverage(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Asri");

		if (this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Coverage can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateForBuildingPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Asri");

		if (this.BuildingPrice == null || this.BuildingPrice === undefined || this.BuildingPrice === 0)
		{
			modelResponse.MessageContent = "Harga Bangunan tidak boleh kosong";
			modelResponse.MessageType ="BuildingPrice";
			return modelResponse;
		}
		else if (this.BuildingPrice > 3000000000)
		{
			modelResponse.MessageContent = "Harga Bangunan tidak boleh lebih dari Rp3.000.000.000.";
			modelResponse.MessageType ="BuildingPrice";
			return modelResponse;
		}
		else if (this.InteriorPrice == null || this.InteriorPrice === undefined || this.InteriorPrice === 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
		else if (this.BuildingPrice < 1000000)
		{
			modelResponse.MessageContent = "Harga Bangunan tidak boleh kurang dari Rp1.000.000.";
			modelResponse.MessageType ="BuildingPrice";
			return modelResponse;
		}
		else if (this.BuildingPrice + this.InteriorPrice > 3000000000)
		{
			modelResponse.MessageContent = "Nilai Pertanggungan tidak boleh lebih dari Rp3.000.000.000";
			modelResponse.MessageType ="InteriorPrice";
			return modelResponse;
		}
		else if (this.InteriorPrice > (this.BuildingPrice / 10))
		{
			modelResponse.MessageContent = "Harga Perlengkapan tidak boleh lebih dari 10% Harga Bangunan.";
			modelResponse.MessageType ="InteriorPrice";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageType ="BuildingPrice";
			return modelResponse;
		}
	}

	validateForInteriorPricePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Asri");

		if (this.InteriorPrice == null || this.InteriorPrice === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			// modelResponse.MessageContent = "Harga Interior tidak boleh kosong";
			modelResponse.MessageType ="InteriorPrice";
			return modelResponse;
		}
		else if (this.InteriorPrice < 100000)
		{
			modelResponse.MessageContent = "Harga Interior harus lebih dari Rp100.000";
			modelResponse.MessageType ="InteriorPrice";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageType ="InteriorPrice";
			return modelResponse;
		}
	}

	validateCheckForCalculatePremiumAsri(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateForCoverage();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForBuildingPrice();
		}
		else
		{
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForInteriorPricePrice();
		}
		else
		{
		}

		return modelResponse;
	}


	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.listModelProductAsriGallery != null && this.listModelProductAsriGallery !== undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
		}


		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhAsriType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhAsriType.BuildingFront,
				Name : "Tampak Depan",
				Path: "\""
			},
			{
				Type: enumPhAsriType.BuildingLeft,
				Name : "Tampak Kiri",
				Path: "\""

			},
			{
				Type: enumPhAsriType.BuildingRight,
				Name : "Tampak Kanan",
				Path: "\""
			},
			{
				Type: enumPhAsriType.BuildingBack,
				Name : "Tampak Belakang",
				Path: "\""
			},
		];

		return arrayModelGalleryModel;
	}


	//#region


	//#region VALIDATION FOR INSURED AND BUTTON

	validateForInsured(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		modelResponse.setForValidation("Product Asri");

		if (this.BuildingAddresss.length < 10)
		{
			modelResponse.MessageContent = "Alamat harus terdiri minimal 10 karakter";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			// modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}


	validateInsuredForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.BuildingPostalCode == null || this.BuildingPostalCode === undefined)
		{
			return modelResponse;
		}
		else if(this.BuildingPostalName == null || this.BuildingPostalName === undefined)
		{
			return modelResponse;
		}
		else if(this.BuildingNearMarket === false)
		{
			return modelResponse;
		}
		else if(this.BuildingWorkPlace === false)
		{
			return modelResponse;
		}
		// else if(this.modelProductGeneral?.PolicyholderAddress == null || this.modelProductGeneral?.PolicyholderAddress === undefined)
		// {
		// 	return modelResponse;
		// }
		// else if(this.BuildingAddress == null || this.BuildingAddress === undefined)
		// {
		// 	return modelResponse;
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}


	validatePremiumSimulationForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.BuildingPrice == null || this.BuildingPrice === undefined)
		{
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}


	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Asri");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Asri");

		if(this.listModelProductAsriGallery == null || this.listModelProductAsriGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductAsriGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductAsriGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductAsriGallery of this.listModelProductAsriGallery ?? [])
			{
				modelResponseProductAsriGallery = modelProductAsriGallery.validateAdd();

				if (modelResponseProductAsriGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductAsriGallery;
				}
				else
				{
					numberSize += modelProductAsriGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductAsriGallery?.filter(modelProductAsriGallery => modelProductAsriGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
					this.booleanDisable = true;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
						this.booleanDisable = false;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}


	validateUploadButtonDisable(): ResponseModel
	{
		let numberDocumentSuccess: number = 0;
		let numberDocumentFailed: number = 0;
		let booleanBreak: boolean = false;

		const modelResponse: ResponseModel = new ResponseModel();

		const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

		for(const modelSupportingDocument of arrayModelSupportingDocument)
		{
			const arrayDocument = this.listModelProductAsriGallery?.filter(modelProductAsriGallery => modelProductAsriGallery.Type === modelSupportingDocument.Type);

			if(arrayDocument?.length === 0)
			{
				if(booleanBreak === false)
				{
					booleanBreak = true;
					numberDocumentFailed = numberDocumentFailed + 1;
					return modelResponse;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
					return modelResponse;
				}
			}
			else
			{
				numberDocumentSuccess = numberDocumentSuccess + 1;
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		return modelResponse;
	}

	//#endregion


	//#region CLEAN

	cleanForPostalCode(): void
	{
		this.BuildingCityName = undefined;
		this.BuildingCountryName = undefined;
		this.BuildingPostalName = undefined;
		this.BuildingProvinceName = undefined;
		this.BuildingPostalID = undefined;
	}

	//#endregion

}

//#endregion
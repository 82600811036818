//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { PremiumModel } from "../models/premium.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductMyHouseModel } from "../models/productmyhouse.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductMyhouseService extends BaseService
{

	//#region DECLARATION

	public _modelProductMyhouse: Observable<ProductMyHouseModel>;
	public _modelProductGeneral: Observable<ProductGeneralModel>;
	public _modelPremium: Observable<PremiumModel>;

	public _boolanProduct: Observable<boolean>;

	private _behaviourSubjectProductMyHouseModel: BehaviorSubject<ProductMyHouseModel>;
	private _behaviourSubjectProductGeneralModel: BehaviorSubject<ProductGeneralModel>;
	private _behaviourSubjectPremiumModel: BehaviorSubject<PremiumModel>;

	private _behaviourBooleanProduct: BehaviorSubject<boolean>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviourSubjectProductMyHouseModel = new BehaviorSubject(new ProductMyHouseModel());
		this._modelProductMyhouse = this._behaviourSubjectProductMyHouseModel.asObservable();

		this._behaviourSubjectProductGeneralModel = new BehaviorSubject(new ProductGeneralModel());
		this._modelProductGeneral = this._behaviourSubjectProductGeneralModel.asObservable();

		this._behaviourSubjectPremiumModel = new BehaviorSubject(new PremiumModel());
		this._modelPremium = this._behaviourSubjectPremiumModel.asObservable();

		this._behaviourBooleanProduct = new BehaviorSubject<boolean>(false);
		this._boolanProduct = this._behaviourBooleanProduct.asObservable();
	}

	//#endregion


	//#region SETTER

	setProductCode(modelProductMyHouse: ProductMyHouseModel)
	{
		this._behaviourSubjectProductMyHouseModel.next(modelProductMyHouse);
	}

	setBooleanProduct(booleanProduct: boolean)
	{
		this._behaviourBooleanProduct.next(booleanProduct);
	}

	setModelProductGeneral(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelProductGeneralPolicy(modelProductGeneral: ProductGeneralModel)
	{
		this._behaviourSubjectProductGeneralModel.next(modelProductGeneral);
	}

	setModelPremium(modelPemium: PremiumModel)
	{
		this._behaviourSubjectPremiumModel.next(modelPemium);
	}


	//#endregion


	//#region CALCULATE

	calculatePremiumForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_CALCULATEPREMIUMFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region INSERT

	insertProductForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_INSERTPRODUCTFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region UPDATE

	updateInsuredForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_UPDATEINSUREDFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region UPLOAD

	uploadProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductMyHouse: ProductMyHouseModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyHouse), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region SELECT

	selectProductByTokenForNewDeliverSystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_SELECTPRODUCTBYTOKENFORNEWDELIVERYSYSTEM);

	}

	//#endregion


	//#region SAVE

	saveProductForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_SAVEPRODUCTFORNEWDELIVERYSYSTEM);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_SENDSPPABYEMAILFORNEWDELIVERYSYSTEM);
	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductMyHouse: ProductMyHouseModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyHouse), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_CONFIRMPAYMENT);

	}

	sharePaymentForNewDeliverySystem(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_SHAREPAYMENTFORNEWDELIVERYSYSTEM);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTBRIVA);
	}

	//#endregion

}

//#endregion

<div id="divBodyDashboard" class="DivBodyGeneral">
	<div id="divMainDashboard">
		<div class="DivBackgroundProduct">
			<img class="ImgIconOto">
			<p>BRINS OTO</p>
		</div>

		<h2>Deskripsi</h2>
		<h4>Melindungi kendaraan bermotor roda 4 (empat) Anda dari kehilangan dan kerusakan yang disebabkan oleh risiko yang tidak terduga (yaitu: tabrakan, benturan, terbalik, tergelincir atau terperosok, perbuatan jahat, pencurian yang diikuti dengan kekerasan atau ancaman).</h4>

		<h2>Harga Pertanggungan</h2>
		<div class="DivPriceValidation MarginTop10">
			<ul>
				<li>Harga Pertanggungan Maksimal s.d. Rp2.000.000.000</li>
				<li>Harga Perlengkapan Minimum Rp100.000</li>
				<li>Harga Perlengkapan Maksimum ≥ 10% Total Harga Kendaraan</li>
			</ul>
		</div>

		<h2>Pilih Paket</h2>

		<div class="DivSelectPackage">
			<div class="DivWrapperSelectedPackage" (click)="openPackageListDialog()">
				<div class="ContainerLabelPackage">
					<ng-container *ngIf="this._modelProductOto.modelCoverage === undefined || this._modelProductOto.modelCoverage === null">
						<span>Paket</span>
						<label>Pilih Paket</label>
					</ng-container>

					<ng-container *ngIf="this._modelProductOto.modelCoverage !== undefined && this._modelProductOto.modelCoverage !== null">
						<div class="DivIconPackage">
							<img src="{{this._modelProductOto.modelCoverage.IconURL}}">
							<div class="DivContainerLabelPackage">
								<span>Paket</span>
								<label>{{this._modelProductOto.modelCoverage.Text}}</label>
							</div>
						</div>
					</ng-container>
				</div>
				<img src="../../../../../assets/inputs/sakina/input_arrow_bottom.svg">
			</div>
		</div>

		<ng-container *ngIf="this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode=== _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM">
			<div class="DivContentPackage">
				<hr>
				<h4>Berikut adalah risiko yang dijamin:</h4>
				
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck">
						<label id="LabelForTitle">Kerugian total</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck DivSingleLine"  [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label id="LabelForTitle">Kerugian Sebagian</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck">
						<label id="LabelForTitle">Tanggung Jawab Hukum Pihak Ketiga</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck"  [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
					<label id="LabelForTitle">Angin Topan, Badai, Kerusakan Akibat Air</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck"  [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label class="LabelMarginLeft20" id="LabelForTitle">Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang Tercantum Dalam Polis (RSMDCC)</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck" [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label id="LabelForTitle">Terorisme dan Sabotase</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck" [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label id="LabelForTitle">Gempa Bumi, Tsunami, Letusan Gunung Berapi</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck" [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label id="LabelForTitle">Kecelakaan Pengemudi</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck" [ngClass]="{'ImgUnCheck': this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOGOLD , 'ImgCheck': _stringConstant.STRING_VALUE_PACKAGE_OTOSILVER || this._modelProductOto.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM}">
						<label id="LabelForTitle">Kecelakaan Penumpang</label>
				</div>
			</div>
		</ng-container>

		<div class="DivWrapperListFormCard MarginLeft15 MarginRight15">
			<div class="DivCardInformation DivCardInformationForPackage">
				<div class="DivFirstCardPackage">
					<div class="DivWrapImgInformation">
						<img class="ImgInformation">
						<p>Informasi</p>
					</div>
					<label>Asuransi BRINS OTO adalah produk asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia. Produk ini bukan merupakan produk dari PT. Bank Rakyat Indonesia (Persero), Tbk serta tidak termasuk dalam program penjaminan Pemerintah Republik Indonesia. PT. Bank Rakyat Indonesia (Persero) Tbk tidak bertanggung jawab atas kepesertaan asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia sehubungan dengan produk Asuransi BRINS OTO. PT. Bank Rakyat Indonesia (Persero), Tbk bukan agen PT. BRI Asuransi Indonesia maupun broker dari nasabah PT. Bank Rakyat Indonesia (Persero), Tbk. PT. BRI Asuransi Indonesia adalah perusahaan asuransi yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</label>
				</div>
			</div>
		</div>

		<input type="button" class="ButtonNext" value="Lanjutkan"  [disabled]="_booleanButtonDisable" (click)="goToPremiumSimulation()">
	</div>
</div>


<!-- START MODAL -->

<div class="DivModal" id="divModalBottomListItem">
	<label class="LabelPackageList">Paket</label>
	<form>
		<ng-container *ngFor="let modalItemBottom of _arrayDisplayed; let numberIndex = index">
			<div class="DivListItemForDropdown">
				<div class="DivContainerIconPackage">
					<input type="button" value="{{ modalItemBottom }}" name="modalDialogBottom"
						id="modalBottomItem{{numberIndex}}" (click)="closeModalBottomList(modalItemBottom)"
						[value]="modalItemBottom.ProductCode">
					<img src="{{modalItemBottom.IconURL}}">
					<label class="labelForPackage" for="modalBottomItem{{numberIndex}}">{{ modalItemBottom.Text }}</label>
				</div>
			</div>
			<hr>
		</ng-container>
	</form>
</div>

<!-- END MODAL -->
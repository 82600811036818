<div id="divBodyDashboard" class="DivBodyGeneral">
	<div id="divMainDashboard">
		<div class="DivBackgroundProduct">
			<img class="ImgIconAsmikDamageofbusinessplace">
			<p>Asuransi Mikro<br>Kerusakan Tempat Usaha</p>
		</div>

		<h2>Deskripsi</h2>
		<h4>Memberikan santunan berupa uang apabila peserta asuransi mengalami kerusakan tempat usaha akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis</h4>

		<h2>Harga Pertanggungan</h2>
		<div class="DivPriceValidation MarginTop10">
			<ul>
				<li>Harga Premi Rp40.000</li>
				<li>Nilai Santunan Rp5.000.000</li>
			</ul>
		</div>

		<div class="DivSelectPackage MarginTop30">
			<div class="DivWrapperSelectedPackage">
				<div class="ContainerLabelPackage">
					<div class="DivIconPackage">
						<img src="../../../../../assets/icons/sakina/icon_ktu_new.svg">
						<div class="DivContainerLabelPackage">
							<label>ASMIK KTU</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-container>
			<div class="DivContentPackage DivContentPackageAsmik MarginTop30">
				<h4>Berikut adalah risiko yang dijamin:</h4>

				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Kebakaran</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Ledakan Kompor atau Tabung Gas</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Kejatuhan Pesawat Terbang</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Kurusuhan</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Tertabrak Kendaraan</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Gempa Bumi</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
					<img class="ImgCheck">
					<label id="labelRisk">Letusan Gunung Berapi dan Tsunami</label>
				</div>
			</div>
		</ng-container>

		<ng-container>
			<div class="DivContentPackage DivContentTotalPremi DivHorizontal">
				<h2>Premi</h2>
				<label>{{_stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{this._modelProductGeneralRequest.PremiumTotalAmount | currencyValue | convertEmptyToDash }}</label>
			</div>
		</ng-container>

		<div class="DivWrapperListFormCard MarginLeft15 MarginRight15">
			<div class="DivCardInformation DivCardInformationForPackage">
				<div class="DivFirstCardPackage">
					<div class="DivWrapImgInformation">
						<img class="ImgInformation">
						<p>Informasi</p>
					</div>
					<label>Asuransi BRINS Mikro Kerusakan Tempat Usaha (KTU) adalah produk asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia. Produk ini bukan merupakan produk dari PT. Bank Rakyat Indonesia (Persero), Tbk serta tidak termasuk dalam program penjaminan Pemerintah Republik Indonesia. PT. Bank Rakyat Indonesia (Persero) Tbk tidak bertanggung jawab atas kepesertaan asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia sehubungan dengan produk Asuransi Mikro Kerusakan Tempat Usaha (KTU). PT. Bank Rakyat Indonesia (Persero), Tbk bukan agen PT. BRI Asuransi Indonesia maupun broker dari nasabah PT. Bank Rakyat Indonesia (Persero), Tbk. PT. BRI Asuransi Indonesia adalah perusahaan asuransi yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</label>
				</div>
			</div>
		</div>
		<input type="button" class="ButtonNext" value="Lanjutkan" (click)="goToPolicyHolder()">
	</div>
</div>
//#region IMPORT

import { Location } from "@angular/common";
import { Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { WebcamImage, WebcamInitError } from "ngx-webcam";
import { Subject, take } from "rxjs";
import { BaseAuthourizeDetailComponent } from "src/app/components/bases/baseauthourizedetail.component";
import { ProductgeneralService } from "../../../../services/productgeneral.service";
import { ProductotoService } from "../../../../services/productoto.service";
import { SessionService } from "../../../../services/session.service";
import { SupportingdocumentComponent } from "../supportingdocument/supportingdocument.component";


//#endregion


//#region COMPONENT
@Component
(
	{
		selector: "app-cameracapture",
		templateUrl: "./cameracapture.component.html",
		styleUrls: ["./cameracapture.component.sass"]
	}
)

//#endregion

export class CameracaptureComponent extends BaseAuthourizeDetailComponent implements OnDestroy
{
	readonly imageTrigger: Subject<void> = new Subject<void>();
	error?: string;
	public _backgroundImageURL: any;
	public _numberDocumentType: any;
	public _stringDataBase64: any;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	constructor(serviceSession: SessionService, router: Router, routeActivated: ActivatedRoute , location: Location, private _serviceProductGeneral: ProductgeneralService, private _serviceProductOto: ProductotoService, private readonly dialogRef: MatDialogRef<SupportingdocumentComponent>)
	{
		super(routeActivated,location, serviceSession, router);
	}

	ngOnInit(): void
	{
		this._serviceProductGeneral._modelProductBaseGallery.pipe(take(1)).subscribe( modelProductGeneral =>
		{
			this._numberDocumentType = modelProductGeneral;

			if(this._numberDocumentType === 10)
			{
				this._backgroundImageURL = "../../../../../assets/icons/sakina/icon_frame_front.svg";
			}
			else if(this._numberDocumentType === 11)
			{
				this._backgroundImageURL = "../../../../../assets/icons/sakina/icon_frame_left.svg";
			}
			else if(this._numberDocumentType === 12)
			{
				this._backgroundImageURL = "../../../../../assets/icons/sakina/icon_frame_right.svg";
			}
			else if(this._numberDocumentType === 13)
			{
				this._backgroundImageURL = "../../../../../assets/icons/sakina/icon_frame_back.svg";
			}
			else
			{
			}
		});
	}

	captureImage(webcamImage: WebcamImage): void
	{
		this.dialogRef.close(webcamImage.imageAsDataUrl);
		this._router.navigate(["product", "supportingdocument"]);
	}

	triggerSnapshot(): void
	{
		this.imageTrigger.next();
	}

	handleInitError(error: WebcamInitError): void
	{
		this.error = JSON.stringify(error);
	}

	ngOnDestroy(): void
	{
		this.imageTrigger.complete();
	}
}

//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABETWITHOUTEMOJI: string = "[A-Za-z]*";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[A-Za-z]";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHSPACE: string = "^[a-zA-Z0-9]";
	static STRING_REGULAREXPRESSION_EMAIL: any = "[a-zA-Z0-9+$\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+$";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "0.0.14";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";

	/* PRODUCT CODE */
	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_BICYCLE: string = "1216";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE: string = "0119";
	static STRING_VALUE_PRODUCT_MYMOTORCYCLE: string = "0210";
	static STRING_VALUE_PRODUCT_MYHOUSE: string = "0114";

	/* COVERAGE CODE */

	// ASRI
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01-V3";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02-V3";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03-V3";

	// OTO
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01-V3";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02-V3";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03-V3";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04-V3";

	// PERSONAL ACCIDENT
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM-V3";

	// BICYCLE
	static STRING_VALUE_PACKAGE_BICYCLESILVER: string = "1216-01-V3";
	static STRING_VALUE_PACKAGE_BICYCLEGOLD: string = "1216-02-V3";
	static STRING_VALUE_PACKAGE_BICYCLEPLATINUM: string = "1216-03-V3";

	// ASMIK RUMAHKU
	static STRING_VALUE_PACKAGE_YEARLY_MYHOUSE: string = "BM-RKU-01";

	// ASMIK KERUSAKAN TEMPAT USAHA
	static STRING_VALUE_PACKAGE_YEARLY_DAMAGEOFBUSINESSPLACE: string = "BM-KTU-01";

	// ASMIK MOTORKU
	static STRING_VALUE_PACKAGE_YEARLY_MYMOTORCYCLE: string = "OTO-07-V3";

	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";

	static STRING_VALUE_PACKAGE_MYMOTORCYCLE: string = "OTO-07";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Merapi - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];

	static ARRAY_VALUE_IMAGE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";
	static STRING_ROUTING_POLICYNUMBER: string = "policynumber";
	static STRING_ROUTING_CLAIMNUMBER: string = "claimnumber";
	static STRING_ROUTING_PAYMENTANO: string = "ano";
	static STRING_ROUTING_PUBLIC: string = "public";
	static STRING_ROUTING_TICKET: string = "ticket";
	static STRING_ROUTING_SIGNIN: string = "signin";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_BASEMODEL: string = "BaseModel";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";

	//#endregion


	//#region ASMIK KTU

	static STRING_TITLE_DAMAGEOFBUSINESSPLACE = "ASMIK KTU";
	static STRING_TITLE_DAMAGEOFBUSINESSPLACE_CAPITAL = "Asuransi Mikro Kerusakan Tempat Usaha";
	static STRING_INFORMATION_TITLE_DAMAGEOFBUSINESSPLACE: string = "Dagangan laris senyum manis";
	static STRING_INFORMATION_DESCRIPTION_DAMAGEOFBUSINESSPLACE: string = "Karena perlindungan ekonomis yang ga bikin dompet tipis";
	static STRING_INFORMATION_PARAGRAPH_DAMAGEOFBUSINESSPLACE: string = "Memberikan santunan berupa uang apabila peserta asuransi mengalami kerusakan tempat usaha akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.";

	//#endregion


	//#region ASMIK RUMAHKU

	static STRING_TITLE_MYHOUSE = "ASMIK Rumahku";
	static STRING_TITLE_MYHOUSE_CAPITAL = "Asuransi Mikro Rumahku";
	static STRING_INFORMATION_TITLE_MYHOUSE: string = "Sederhana namun penuh makna";
	static STRING_INFORMATION_DESCRIPTION_MYHOUSE: string = "Karena bahagia tercipta dirumah bersama keluarga";
	static STRING_INFORMATION_PARAGRAPH_MYHOUSE: string = "Memberikan proteksi terhadap bangunan tempat tinggal tertanggung atau tempat tinggal merangkap tempat usaha rusak akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.";

	//#endregion


	//#region ASMIK MOTORKU

	static STRING_TITLE_MYMOTORCYCLE = "ASMIK Motorku";
	static STRING_TITLE_MYMOTORCYCLE_CAPITAL = "Asuransi Mikro Motorku";
	static STRING_INFORMATION_TITLE_MYMOTORCYCLE: string = "Ngacir tanpa khawatir";
	static STRING_INFORMATION_DESCRIPTION_MYMOTORCYCLE: string = "Lewati rintangan di setiap perjalanan";
	static STRING_INFORMATION_PARAGRAPH_MYMOTORCYCLE: string = "Memberikan santunan kepada tertanggung dengan manfaat santunan atas kerugian motor, santunan terhadap peserta yang mengendarai motor dan santunan tanggung jawab pihak ketiga, yang menyebabkan pihak lain meninggal dunia/cacat tetap.";

	//#endregion

}

//#endregion
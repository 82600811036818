//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { ProductAsriModel } from "./productasri.model";
// import { ProductAsriSyariahModel } from "./productasrisyariah.model";
import { ProductOtoModel } from "./productoto.model";
// import { ProductOtoSyariahModel } from "./productotosyariah.model";
// import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
// import { ProductPersonalAccidentSyariahModel } from "./produtpersonalaccidentsyariah.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
// import { VirtualAccountModel } from "./virtualaccount.model";
import { DiscountModel } from "./discount.model";
import { ProductBicycleModel } from "./productbicycle.model";
import { VirtualAccountModel } from "./virtualaccount.model copy";
import { ProductDamageOfBusinessPlaceModel } from "./productdamageofbusinessplace.model";
import { ProductMyHouseModel } from "./productmyhouse.model";
import { ProductMyMotorcycleModel } from "./productmymotorcycle.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	LeadToken?: string;

	CustomerID?: number;
	RelationshipManagerID?: number;
	Token?: string;

	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderBirthDate?: any;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyholderEmailForLocalStorage?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderProvinceID?: number;
	PolicyholderCityID?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;
	PolicyholderPotentialAmount?: number;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: any;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;
	PremiumTotalAmountWithDiscount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	CoverageCode?: string;
	PaymentAccountID?: number;

	VirtualAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SavedBy?: number;
	SavedOn?: Date;
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;

	/* TRACKING - END */

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	SourceID?: string;

	/* CARE TECH - END */

	/* NOT MAPPED - START */

	modelProductAsri?: ProductAsriModel;
	modelUser?: UserModel;
	modelProductOto?: ProductOtoModel;
	modelProductBicycle?: ProductBicycleModel;
	modelProductDamageOfBusinessPlace?: ProductDamageOfBusinessPlaceModel;
	modelProductMyHouse?: ProductMyHouseModel;
	modelProductMyMotorcycle?: ProductMyMotorcycleModel;
	modelVirtualAccount?: VirtualAccountModel;
	modelDiscount?: DiscountModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelDiscount = new DiscountModel();
		this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		this.modelProductMyHouse = new ProductMyHouseModel();
		this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();

		const stringSelectedProduct = localStorage.getItem("SelectedProduct");

		if(stringSelectedProduct === "ASRI")
		{
			this.modelProductAsri = new ProductAsriModel();
		}
		else if(stringSelectedProduct === "OTO")
		{
			this.modelProductOto = new ProductOtoModel();
		}
		else if(stringSelectedProduct === "BICYCLE")
		{
			this.modelProductBicycle = new ProductBicycleModel();
		}
		else if(stringSelectedProduct === "ASMIKDAMAGEOFBUSINESSPLACE")
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		}
		else
		{
		}
	}

	//#endregion


	//#region FUNCTION

	getClearCalculate(): void
	{
		this.PremiumAmount = 0;
		this.AdministrationAmount = 0;
		this.StampAmount = 0;
		this.PremiumTotalAmount = 0;
	}

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined)
		{
			this.PremiumTotalAmount = this.AdministrationAmount + this.StampAmount + this.PremiumAmount;
		}
		else
		{

		}
	}

	//#endregion


	//#region VALIDATE

	validateForProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.VehicleBrandCode == null || this.VehicleBrandCode === undefined || this.VehicleBrandCode === "")
		{
			modelResponse.MessageContent = "Model Vehicle Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateForPolicyHolder(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpressionForEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		modelResponse.setForValidation("Product General");

		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined)
		{
			modelResponse.MessageContent = "First Name can't be empty";
			return modelResponse;
		}
		else if (this.PolicyholderFirstName.length > 40)
		{
			modelResponse.MessageContent = "Nama Depan tidak boleh lebih dari 40 karakter";
			return modelResponse;
		}
		// else if (!regularExpressionAlfabet.test(this.PolicyholderFirstName))
		// {
		// 	modelResponse.MessageContent = "Masukkan Nama Depan yang benar";
		// 	return modelResponse;
		// }

		else if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined)
		{
			modelResponse.MessageContent = "Last Name can't be empty";
			return modelResponse;
		}
		// else if (!regularExpressionAlfabet.test(this.PolicyholderLastName) || this.PolicyholderLastName.length <= 3)
		// {
		// 	modelResponse.MessageContent = "Masukkan Nama Belakang yang benar";
		// 	return modelResponse;
		// }

		else if (this.PolicyholderBirthDate == null || this.PolicyholderBirthDate === undefined)
		{
			modelResponse.MessageContent = "Birthdate can't be empty";
			return modelResponse;
		}
		// else if (this.PolicyholderBirthDate?.getFullYear() > 2005)
		// {
		// 	modelResponse.MessageContent = "Policyholder minimum age is 17 years old";
		// 	return modelResponse;
		// }

		else if (this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined)
		{
			modelResponse.MessageContent = "Identification number can't be empty";
			return modelResponse;
		}
		else if (this.PolicyholderIdentificationNumber?.length < 16 || this.PolicyholderIdentificationNumber?.length > 16)
		{
			modelResponse.MessageContent = "KTP harus terdiri 16 digit";
			return modelResponse;
		}

		else if (this.PolicyholderAddress == null || this.PolicyholderAddress === undefined)
		{
			modelResponse.MessageContent = "Address can't be empty";
			return modelResponse;
		}
		else if (this.PolicyholderAddress.length < 10)
		{
			modelResponse.MessageContent = "Alamat harus terdiri minimal 10 karakter";
			return modelResponse;
		}

		else if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined)
		{
			modelResponse.MessageContent = "Email can't be empty";
			return modelResponse;
		}
		else if (!regularExpressionForEmail.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Email invalid";
			return modelResponse;
		}

		else if (this.PolicyholderMobilePhone == null || this.PolicyholderMobilePhone === undefined)
		{
			modelResponse.MessageContent = "Mobile phone can't be empty";
			return modelResponse;
		}
		else if (this.PolicyholderMobilePhone.length < 10)
		{
			modelResponse.MessageContent = "Nomor Ponsel invalid";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			// modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateForPolicyHolderForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined)
		{
			return modelResponse;
		}
		else if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined)
		{
			return modelResponse;
		}
		// else if (this.PolicyholderBirthDate == null || this.PolicyholderBirthDate === undefined)
		// {
		// 	return modelResponse;
		// }
		else if (this.PolicyholderIdentificationNumber == null || this.PolicyholderIdentificationNumber === undefined)
		{
			return modelResponse;
		}
		else if (this.PolicyholderAddress == null || this.PolicyholderAddress === undefined)
		{
			return modelResponse;
		}
		else if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined)
		{
			return modelResponse;
		}
		else if (this.PolicyholderMobilePhone == null || this.PolicyholderMobilePhone === undefined)
		{
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	validateForDamageOfBusinessPlaceCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		// modelResponse.setForValidation("Produk KTU");

		// if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		// {
		// 	modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		// }
		// else if(this.modelProductDamageOfBusinessPlace == null)
		// {
		// 	modelResponse.MessageContent = "Produk KTU tidak boleh kosong.";
		// }
		// else if(this.modelProductDamageOfBusinessPlace.CoverageCode == null || this.modelProductDamageOfBusinessPlace.CoverageCode === "" || this.modelProductDamageOfBusinessPlace.CoverageCode === "")
		// {
		// 	modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		// }
		// else
		// {
		// 	modelResponse.MessageContent = "Form is filled correctly.";
		// 	modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// }

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setModelVirtualAccount(): void
	{
		const stringModelVirtualAccount: string = JSON.stringify(this.modelVirtualAccount);
		this.modelVirtualAccount = new VirtualAccountModel();
		this.modelVirtualAccount.setModelFromString(stringModelVirtualAccount);
	}

	setModelProductAsri(): void
	{
		const stringModelProductAsri: string = JSON.stringify(this.modelProductAsri);
		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsri.setModelFromString(stringModelProductAsri);
	}

	setModelProductOto(): void
	{
		const stringModelProductOto: string = JSON.stringify(this.modelProductOto);
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOto.setModelFromString(stringModelProductOto);
	}

	setModelProductBicycle(): void
	{
		const stringModelProductBicycle: string = JSON.stringify(this.modelProductBicycle);
		this.modelProductBicycle = new ProductBicycleModel();
		this.modelProductBicycle.setModelFromString(stringModelProductBicycle);
	}

	setModelProductDamageofbusinessplace(): void
	{
		const stringModelProductDamageofbusinessplace: string = JSON.stringify(this.modelProductDamageOfBusinessPlace);
		this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		this.modelProductDamageOfBusinessPlace.setModelFromString(stringModelProductDamageofbusinessplace);
	}

	setModelProductMyHouse(): void
	{
		const stringModelProductMyHouse: string = JSON.stringify(this.modelProductMyHouse);
		this.modelProductMyHouse = new ProductMyHouseModel();
		this.modelProductMyHouse.setModelFromString(stringModelProductMyHouse);
	}

	setModelProductMyMotorcycle(): void
	{
		const stringModelProductMyMotorcycle: string = JSON.stringify(this.modelProductMyMotorcycle);
		this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
		this.modelProductMyMotorcycle.setModelFromString(stringModelProductMyMotorcycle);
	}

	setProductASMIKDamageOfBusinessPlace(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE;
		this.ProductName = StringConstant.STRING_TITLE_DAMAGEOFBUSINESSPLACE;
	}

	setProductASMIKMyHouse(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_MYHOUSE;
		this.ProductName = StringConstant.STRING_TITLE_MYHOUSE;
	}

	setProductASMIKMyMotorcycle(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE;
		this.ProductName = StringConstant.STRING_TITLE_MYMOTORCYCLE;
		this.StampAmount = 0;
	}

	setForUpdateBeneficiariesDamageOfBusinessPlace(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;

		if (modelProductGeneral.modelProductDamageOfBusinessPlace !== null || modelProductGeneral.modelProductDamageOfBusinessPlace !== undefined)
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
			this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace;
			this.modelProductDamageOfBusinessPlace.listModelProductDamageOfBusinessPlaceGallery = undefined;
		}
		this.Token = modelProductGeneral.Token;
	}

	setForUpdateInsuredDamageOfBusinessPlace(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;

		if(modelProductGeneral.modelProductDamageOfBusinessPlace != null || modelProductGeneral.modelProductDamageOfBusinessPlace !== undefined )
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
			this.modelProductDamageOfBusinessPlace.BuildingAddress = modelProductGeneral.modelProductDamageOfBusinessPlace.BuildingAddress;
			this.modelProductDamageOfBusinessPlace.BuildingPostalCode = modelProductGeneral.modelProductDamageOfBusinessPlace.BuildingPostalCode;
			this.modelProductDamageOfBusinessPlace.BuildingPostalName = modelProductGeneral.modelProductDamageOfBusinessPlace.BuildingPostalName;
			this.modelProductDamageOfBusinessPlace.LocationInformation = modelProductGeneral.modelProductDamageOfBusinessPlace.LocationInformation;
			this.modelProductDamageOfBusinessPlace.OwnershipInformation = modelProductGeneral.modelProductDamageOfBusinessPlace.OwnershipInformation;
			this.modelProductDamageOfBusinessPlace.CompensationValue = modelProductGeneral.modelProductDamageOfBusinessPlace.CompensationValue;
			this.modelProductDamageOfBusinessPlace.Token = modelProductGeneral.modelProductDamageOfBusinessPlace.Token;
			this.modelProductDamageOfBusinessPlace.listModelProductDamageOfBusinessPlaceGallery = undefined;
			this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = undefined;
		}
	}

	setProductGeneralPolicyHolderFromUserModelSignIn(modelUser: UserModel): void
	{
		this.PolicyholderAddress = modelUser.Address;
		this.PolicyholderBirthDate = modelUser.BirthDate;
		this.PolicyholderBirthPlace = modelUser.BirthPlace;
		this.PolicyholderEmail = modelUser.Email;
		this.PolicyholderFirstName = modelUser.FirstName;
		this.PolicyholderLastName = modelUser.LastName;
		this.PolicyholderMiddleName = modelUser.MiddleName;
		this.PolicyholderMobilePhone = modelUser.MobilePhone;
		this.PolicyholderGender = modelUser.Gender;
		this.PolicyholderIdentificationNumber = modelUser.IdentificationNumber;
		this.PolicyholderIdentificationType = modelUser.IdentificationType;
		this.PolicyholderPotentialAmount = this.PremiumTotalAmount;

		if (modelUser.modelBranch !== undefined)
		{
			this.PolicyholderBranchID = modelUser.modelBranch.ID;
			this.PolicyholderBranchCode = modelUser.modelBranch.Code;
			this.PolicyholderBranchName = modelUser.modelBranch.Name;
		}
		else
		{

		}
	}

	//#endregion


	//#region CLEAN

	cleanProductAsmik(): void
	{
		this.modelProductMyHouse = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
	}

	cleanForProductDamageOfBusinessPlace(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
	}

	cleanForProductMyHouse(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
	}

	cleanForProductMyMotorcycle(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;
	}

	//#endregion

}

//#endregion
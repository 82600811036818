
// #region  IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ResponseModel } from "src/app/models/response.model";
import { NumberConstant } from "../../../../constants/number.constant";
import { ResponseCodeConstant } from "../../../../constants/responsecode.constant";
import { BaseModel } from "../../../../models/bases/base.model";
import { CoverageModel } from "../../../../models/coverage.model";
import { ProductAsriModel } from "../../../../models/productasri.model";
import { UserModel } from "../../../../models/user.model";
import { UserSessionModel } from "../../../../models/usersession.model";
import { HandshakeService } from "../../../../services/handshake.service";
import { ProductasriService } from "../../../../services/productasri.service";
import { SessionService } from "../../../../services/session.service";
import { BaseAuthourizeDetailComponent } from "../../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-asripackage",
		templateUrl: "./asripackage.component.html",
		styleUrls: ["./asripackage.component.sass"]
	}
)

//#endregion


//#region  CLASS

export class AsripackageComponent extends BaseAuthourizeDetailComponent
{
	//#region  DECLARATION

	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductAsri: ProductAsriModel;
	public _modelHandshake: HandshakeModel;
	public _modelBase: BaseModel;

	public _stringClassForTitle?: any;
	public _stringConstant = StringConstant;

	public _booleanGender: boolean | null;
	public _booleanButtonDisable: boolean | null;

	public _numberConstant = NumberConstant;

	public _arrayDisplayed: Array<any> = [];
	public _arrayPackage: Array<CoverageModel> =
		[
			{Text: "Silver", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_ASRISILVER, IconURL: "../../../../../assets/icons/sakina/icon_package_silver.svg"},
			{Text: "Gold", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_ASRIGOLD, IconURL: "../../../../../assets/icons/sakina/icon_package_gold.svg"},
			{Text: "Platinum", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM, IconURL: "../../../../../assets/icons/sakina/icon_package_platinum.svg"},
		];

	private _elementHTMLDivModal: HTMLElement | null;

	//#endregion


	//#region  CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, routeActivated: ActivatedRoute , location: Location, private _serviceProductAsri: ProductasriService, private _serviceHandshake: HandshakeService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelBase = new BaseModel();
		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductAsri = new ProductAsriModel();
		this._modelHandshake = new HandshakeModel();
		this._booleanGender = null;
		this._booleanButtonDisable = true;
		this._elementHTMLDivModal = document.getElementById("divModalBottomListItem");
	}

	//#endregion


	//#region  INTITALIZATION

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelHandshake.Token = stringToken;
			this.callSelectHandshakeByTokenForNewDeliverySystem();
		}
		else
		{
		}

		this._modelProductGeneral.cleanProductAsmik();
		this._elementHTMLDivModal = document.getElementById("divModalBottomListItem");
	}

	//#endregion


	//#region  NAVIGATION

	goToPremiumSimulation()
	{
		this._booleanButtonDisable = true;
		this._router.navigate(["product", "asri", "asripremiumsimulation"]);
	}

	//#endregion


	//#region FUNCTION

	closeModalBottomList(modelCoverage: CoverageModel)
	{
		this._modelBase.ProductCode = StringConstant.STRING_VALUE_PRODUCT_ASRI;

		if(modelCoverage !== undefined)
		{
			if(modelCoverage.PackageCode !== undefined)
			{
				this._modelProductAsri.CoverageCode = modelCoverage.PackageCode;
				this._modelProductAsri.modelCoverage = modelCoverage;

				this._serviceProductAsri.setProductCode(this._modelProductAsri);
				this._modelBase.modelProductAsri = this._modelProductAsri;
				this._serviceSession.setModelBase(this._modelBase);

				this._booleanButtonDisable = false;
			}
			else
			{
			}
		}

		if(this._elementHTMLDivModal)
		{
			this._functionUserInterface.hideCurtainLoading();
			this._elementHTMLDivModal.style.bottom = "-1000px";

		}
		else
		{
		}
	}


	openPackageListDialog()
	{
		this._arrayDisplayed = [];
		for(const stringItem of this._arrayPackage)
		{
			this._arrayDisplayed.push(stringItem);
		}
		if(this._elementHTMLDivModal)
		{
			this._functionUserInterface.showCurtainLoadingForDropdown();
			this._elementHTMLDivModal.style.bottom = "0";
		}
	}

	//#endregion


	//#region WEBSERVICE

	callSelectHandshakeByTokenForNewDeliverySystem(): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const componentCurrent: AsripackageComponent = this;

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.Token = this._modelHandshake.Token;

		this._serviceHandshake.selectHandshakeByTokenForNewDeliverySystem
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								componentCurrent._modelHandshake = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);

								if(modelUserResponse.Token != null && modelUserResponse.Token !== undefined && modelUserResponse.Token !== "" )
								{
									const modelUserSession = new UserSessionModel();
									modelUserSession.UserToken = modelUserResponse.Token;
									componentCurrent._serviceSession.setUserSession(modelUserSession);
								}
								else
								{
									componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
								}

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHandshakeByTokenForNewDeliverySystem(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
					componentCurrent._router.navigate(["sessionexpired"]);
				});
			}
		}, this._modelHandshake);
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxImageCompressService } from "ngx-image-compress";
import { NgxMaskModule } from "ngx-mask";
import { WebcamModule } from "ngx-webcam";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ModaldropdownComponent } from "./components/includes/modaldropdown/modaldropdown.component";
import { AsmikdamageofbusinessplaceinsuredComponent } from "./components/product/asmikdamageofbusinessplace/asmikdamageofbusinessplaceinsured/asmikdamageofbusinessplaceinsured.component";
import { AsmikdamageofbusinessplacepackageComponent } from "./components/product/asmikdamageofbusinessplace/asmikdamageofbusinessplacepackage/asmikdamageofbusinessplacepackage.component";
import { AsmikmyhouseinsuredComponent } from "./components/product/asmikmyhouse/asmikmyhouseinsured/asmikmyhouseinsured.component";
import { AsmikmyhousepackageComponent } from "./components/product/asmikmyhouse/asmikmyhousepackage/asmikmyhousepackage.component";
import { AsmikmymotorcycleinsuredComponent } from "./components/product/asmikmymotorcycle/asmikmymotorcycleinsured/asmikmymotorcycleinsured.component";
import { AsmikmymotorcyclepackageComponent } from "./components/product/asmikmymotorcycle/asmikmymotorcyclepackage/asmikmymotorcyclepackage.component";
import { AsriinsuredComponent } from "./components/product/asri/asriinsured/asriinsured.component";
import { AsripackageComponent } from "./components/product/asri/asripackage/asripackage.component";
import { AsripremiumsimulationComponent } from "./components/product/asri/asripremiumsimulation/asripremiumsimulation.component";
import { BicycleinsuredComponent } from "./components/product/bicycle/bicycleinsured/bicycleinsured.component";
import { BicyclepackageComponent } from "./components/product/bicycle/bicyclepackage/bicyclepackage.component";
import { BicyclepremiumsimulationComponent } from "./components/product/bicycle/bicyclepremiumsimulation/bicyclepremiumsimulation.component";
import { OtoinsuredComponent } from "./components/product/oto/otoinsured/otoinsured.component";
import { OtopackageComponent } from "./components/product/oto/otopackage/otopackage.component";
import { OtopremiumsimulationComponent } from "./components/product/oto/otopremiumsimulation/otopremiumsimulation.component";
import { ProductModule } from "./components/product/product.module";
import { CameracaptureComponent } from "./components/product/shared/cameracapture/cameracapture.component";
import { PolicyholderComponent } from "./components/product/shared/policyholder/policyholder.component";
import { PremiumresultComponent } from "./components/product/shared/premiumresult/premiumresult.component";
import { ProductdiscountComponent } from "./components/product/shared/productdiscount/productdiscount.component";
import { PurchasingdetailComponent } from "./components/product/shared/purchasingdetail/purchasingdetail.component";
import { PurchasingsummaryComponent } from "./components/product/shared/purchasingsummary/purchasingsummary.component";
import { SupportingdocumentComponent } from "./components/product/shared/supportingdocument/supportingdocument.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { CustomcurrencyDirective } from "./functions/directives/customcurrency.directive";
import { PipeModule } from "./functions/pipes/pipe.module";


;

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			AsripackageComponent,
			AsriinsuredComponent,
			AsripremiumsimulationComponent,
			OtopackageComponent,
			OtopremiumsimulationComponent,
			OtoinsuredComponent,
			BicycleinsuredComponent,
			BicyclepremiumsimulationComponent,
			BicyclepackageComponent,
			AsmikdamageofbusinessplacepackageComponent,
			AsmikdamageofbusinessplaceinsuredComponent,
			AsmikmyhousepackageComponent,
			AsmikmyhouseinsuredComponent,
			AsmikmymotorcyclepackageComponent,
			AsmikmymotorcycleinsuredComponent,
			ModaldropdownComponent,
			CustomcurrencyDirective,
			PremiumresultComponent,
			PolicyholderComponent,
			ProductdiscountComponent,
			PurchasingdetailComponent,
			PurchasingsummaryComponent,
			SupportingdocumentComponent,
			CameracaptureComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			MatDialogModule,
			ReactiveFormsModule,
			NgSelectModule,
			NgbModule,
			WebcamModule,
			MatDialogModule,
			ProductModule,
			NgxMaskModule.forRoot(),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: true,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.production,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
		],
		providers: [CurrencyPipe, NgxImageCompressService, DecimalPipe, DatePipe, {
			provide: MatDialogRef,
			useValue: {}
		},],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion
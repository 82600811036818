/* eslint-disable @typescript-eslint/no-unsafe-return */
//#region IMPORT

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of, PartialObserver } from "rxjs";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import "../../functions/extension.function";
import { HandshakeService } from "../handshake.service";
import { SessionService } from "../session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class AuthorizedGuardService implements CanActivate
{
	//#region DECLARATION

	private _modelHandshakeSignIn: HandshakeModel | null;

	private _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceHandshake: HandshakeService, private _router: Router, private _serviceSession: SessionService)
	{
		this._modelHandshakeSignIn = new HandshakeModel();

		const observerPartialModelHandshake: PartialObserver<HandshakeModel> =
		{
			next: (modelHandshake: HandshakeModel) => { this._modelHandshakeSignIn = modelHandshake; },
			error: () => { },
			complete: () => { }
		};

		_serviceSession._modelHandshakeSignIn.subscribe(observerPartialModelHandshake);

		this._functionUserInterface = new UserInterfaceFunction();
	}

	//#endregion


	//#region ACTIVATE

	canActivate(): Observable<boolean>
	{
		try
		{
			let booleanSignIn: boolean = false;

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				booleanSignIn = true;
			}
			else
			{
				this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					if (this._modelHandshakeSignIn != null)
					{
						this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

						booleanSignIn = true;
					}
					else
					{

					}
				}
				else
				{

				}
			}

			if (booleanSignIn)
			{
				const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
				if (modelUserSession != null)
				{
					if (modelUserSession.UserToken != null && modelUserSession.UserToken !== undefined && modelUserSession.UserToken !== "" )
					{
						return this.callRefreshTokenForNewDeliverySystem();
					}
					else
					{
						this.signOut();
						return of(false);
					}
				}
				else
				{
					this.signOut();
					return of(false);
				}
			}
			else
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(); });
				return of(false);
			}
		}
		catch (error)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setSessionExpired();
			modelResponse.MessageContent = (error as Error).message;

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(); });

			return of(false);
		}
	}

	//#endregion


	//#region CALL WEBSITE SERVICE

	private callRefreshTokenForNewDeliverySystem(): Observable<boolean>
	{
		this._functionUserInterface.setLoadingProgress(1);

		if (this._modelHandshakeSignIn != null)
		{
			const componentCurrent: AuthorizedGuardService = this;
			const modelHandshake: HandshakeModel = new HandshakeModel();
			modelHandshake.Token = this._modelHandshakeSignIn.Token;

			return this._serviceHandshake.refreshTokenForNewDeliverySystem
			(
				{
					success(modelResponse: ResponseModel): boolean
					{
						if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
						{
							if (modelResponse.Data !== undefined)
							{
								const modelUser: UserModel = new UserModel();
								modelUser.setModelFromString(modelResponse.Data);
								componentCurrent._serviceSession.setModelUserSignIn(modelUser);

								// if (modelUser.modelCustomer !== undefined)
								// {
								// 	const modelCustomer: CustomerModel = new CustomerModel();
								// 	modelCustomer.setModelFromObject(modelUser.modelCustomer);
								// 	componentCurrent._serviceSession.setModelCustomerSignIn(modelCustomer);
								// }
								// else
								// {
								// 	return false;
								// }

								if (modelUser.modelHandshake !== undefined)
								{
									const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
									modelHandshakeResponse.setModelFromObject(modelUser.modelHandshake);
									componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);

									componentCurrent._functionUserInterface.updateLoadingProgress();

									return true;
								}
								else
								{
									componentCurrent._functionUserInterface.updateLoadingProgress();
									return false;
								}
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
								return false;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
							componentCurrent._functionUserInterface.updateLoadingProgress();

							return false;
						}
					},
					fail(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(); });
						componentCurrent._functionUserInterface.updateLoadingProgress();
						return of(false);
					},
					failNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(); });
						componentCurrent._functionUserInterface.updateLoadingProgress();
						return false;
					},
					signOut(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
						componentCurrent._functionUserInterface.updateLoadingProgress();
						return of(false);
					},
					signOutNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
						componentCurrent._functionUserInterface.updateLoadingProgress();
						return false;
					}
				}, modelHandshake
			);
		}
		else
		{
			return of(false);
		}
	}

	//#endregion


	//#region FUNCTION

	private validateModelHandshake(modelHandshake?: HandshakeModel | null): boolean
	{
		if (modelHandshake == null || modelHandshake === undefined)
		{
			return false;
		}
		else
		{
			if (modelHandshake.Token != null && modelHandshake.Token.validateEmpty())
			{
				return true;
			}
			else
			{
				return false;
			}
		}
	}

	private signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate(["sessionexpired"]);
	}

	//#endregion
}

//#endregion
//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
// import { LicensePlatePrefixModel } from "./licenseplateprefix.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";
import { VehicleBrandModel } from "./vehiclebrand.model";
import { VehicleLocationModel } from "./vehiclelocation.model";
import { VehicleManufactureYearModel } from "./vehiclemanufactureyear.model";
import { VehicleModelModel } from "./vehiclemodel.model";
// import { VehicleBrandModel } from "./vehiclebrand.model";
// import { VehicleColorModel } from "./vehiclecolor.model";
// import { VehicleFunctionModel } from "./vehiclefunction.model";
// import { VehicleLocationModel } from "./vehiclelocation.model";
// import { VehicleManufactureYearModel } from "./vehiclemanufactureyear.model";
// import { VehicleModelModel } from "./vehiclemodel.model";
// import { VehicleSubModelModel } from "./vehiclesubmodel.model";
// import { VehicleTypeModel } from "./vehicletype.model";
import { CoverageModel } from "./coverage.model";

//#endregion


//#region CLASS

export class ProductOtoModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product oto model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	ProductCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	SurveyOtoID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	VehiclePrice?: number;
	EquipmentNonStandardPrice?: number;
	EquipmentNonStandardNote?: string;
	VehicleRegionCode?: string;
	LicensePlatePrefixID?: number;
	LicensePlateCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	VehicleManufactureYearID?: number;
	VehicleManufactureYearCode?: string;
	VehicleManufactureYearName?: string;
	VehicleBrandID?: number;
	VehicleBrandCode?: string;
	VehicleModelID?: number;
	VehicleModelCode?: string;
	VehicleModelName?: string;
	VehicleSubModelID?: number;
	VehicleSubModelCode?: string;
	VehicleSubModelName?: string;
	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleColorID?: number;
	VehicleColorCode?: number;
	VehicleFunctionID?: number;
	VehicleFunctionCode?: string;
	VehicleLocationID?: number;
	VehicleLocationCode?: number;

	VehicleSerie?: string;
	VehicleChasisNumber?: string;
	VehicleEngineNumber?: string;
	VehicleSeatCapacity?: string;
	AuthorizedWorkshop?: boolean;

	/* INSURED - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	modelVehicleBrand?: VehicleBrandModel;
	modelVehicleModel?: VehicleModelModel;
	modelVehicleManufactureYear?: VehicleManufactureYearModel;
	modelVehicleLocation?: VehicleLocationModel;
	modelCoverage?: CoverageModel;
	// modelVehicleSubModel?: VehicleSubModelModel;
	// modelVehicleType?: VehicleTypeModel;
	// modelVehicleColor?: VehicleColorModel;
	// modelVehicleFunction?: VehicleFunctionModel;
	// modelLicensePlatePrefix?: LicensePlatePrefixModel;

	listModelProductOtoGallery?: Array<ProductBaseGalleryModel>;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelProductOtoGallery = [];
	}

	//#endregion


	//#region VALIDATION

	validateforVehiclePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Harga Kendaraan tidak boleh kosong";
			return modelResponse;
		}
		if(this.EquipmentNonStandardPrice == null || this.EquipmentNonStandardPrice === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
		else if (this.VehiclePrice < 1000000)
		{
			modelResponse.MessageContent = "Harga Kendaraan harus lebih dari Rp1.000.000";
			return modelResponse;
		}
		else if (this.VehiclePrice > 2000000000)
		{
			modelResponse.MessageContent = "Harga Kendaraan harus kurang dari Rp2.000.000.000";
			return modelResponse;
		}
		else if (this.VehiclePrice < 1000000)
		{
			modelResponse.MessageContent = "Harga Kendaraan tidak boleh kurang dari Rp1.000.000.";
			return modelResponse;
		}
		else if (this.VehiclePrice + this.EquipmentNonStandardPrice > 2000000000)
		{
			modelResponse.MessageContent = "Nilai Pertanggungan tidak boleh lebih dari Rp2.000.000.000";
			return modelResponse;
		}
		// else if (this.EquipmentNonStandardPrice < 100000)
		// {
		// 	modelResponse.MessageContent = "Nilai Perlengkapan Non Standar harus lebih dari Rp100.000";
		// 	return modelResponse;
		// }
		else if (this.EquipmentNonStandardPrice > (this.VehiclePrice / 10))
		{
			modelResponse.MessageContent = "Harga Perlengkapan Non Standar tidak boleh lebih dari 10% Harga Kendaraan.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforEquipmentNonStandardPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.EquipmentNonStandardPrice == null || this.EquipmentNonStandardPrice === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Vehicle Price can't be empty.";
			return modelResponse;
		}
		// else if (this.EquipmentNonStandardPrice > (this.VehiclePrice / 10))
		// {
		// 	modelResponse.MessageContent = "Harga Perlengkapan Non Standar harus kurang dari 10% Harga Kendaraan.";
		// 	return modelResponse;
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleManufactureYearCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			modelResponse.MessageContent = "Vehicle Manufacture Year can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforCoverageCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.CoverageCode == null || this.CoverageCode === undefined)
		{
			modelResponse.MessageContent = "Coverage can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleBrandCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleBrandCode == null || this.VehicleBrandCode === undefined)
		{
			modelResponse.MessageContent = "Brand can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleModelCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleModelCode == null || this.VehicleModelCode === undefined)
		{
			modelResponse.MessageContent = "Model can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleLocationCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Oto");

		if(this.VehicleLocationCode == null || this.VehicleLocationCode === undefined)
		{
			modelResponse.MessageContent = "Location can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateCheckForCalculatePremiumOto(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateforCoverageCode();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleBrandCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleModelCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleLocationCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleManufactureYearCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehiclePrice();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforEquipmentNonStandardPrice();
		}
		else
		{

		}

		return modelResponse;
	}


	validateforVehicleCodeForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleBrandCode == null || this.VehicleBrandCode === undefined)
		{
			return modelResponse;
		}
		else if(this.VehicleModelCode == null || this.VehicleModelCode === undefined)
		{
			return modelResponse;
		}
		else if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			return modelResponse;
		}
		else if(this.VehicleLocationCode == null || this.VehicleLocationCode === undefined)
		{
			return modelResponse;
		}
		else if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			return modelResponse;
		}
		// else if(this.EquipmentNonStandardNote == null || this.EquipmentNonStandardNote === undefined)
		// {
		// 	return modelResponse;
		// }
		// else if(this.EquipmentNonStandardPrice == null || this.EquipmentNonStandardPrice === undefined)
		// {
		// 	return modelResponse;
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.VehicleFront,
				Name : "Tampak Depan"
			},
			{
				Type: enumPhotoType.VehicleLeft,
				Name : "Tampak Kiri"
			},
			{
				Type: enumPhotoType.VehicleRight,
				Name : "Tampak Kanan"
			},
			{
				Type: enumPhotoType.VehicleBack,
				Name : "Tampak Belakang"
			},
			{
				Type: enumPhotoType.VehicleInterior,
				Name : "Interior"
			},
			{
				Type: enumPhotoType.STNK,
				Name : "STNK"
			},
			{
				Type: enumPhotoType.KTP,
				Name : "KTP"
			},
		];

		return arrayModelGalleryModel;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductOtoGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region GENERATE

	generateManufactureYear(): Array<number>
	{
		// deepcode ignore ArrayConstructor: <please specify a reason of ignoring this>
		const arrayNumberManufactureYear: Array<number> = new Array<number>();
		const numberYear: number = new Date().getFullYear();
		const numberMinimunYear: number = numberYear - 10;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}

	//#endregion


	//#region VALIDATE OTO INSURED


	validateInsuredForButton(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleSubModelCode == null || this.VehicleSubModelCode === undefined)
		{
			return modelResponse;
		}
		else if(this.VehicleColorCode == null || this.VehicleColorCode === undefined)
		{
			return modelResponse;
		}
		// else if(this.VehicleFunctionCode == null || this.VehicleFunctionCode === undefined)
		// {
		// 	return modelResponse;
		// }
		// else if(this.VehicleLicensePrefixCode == null || this.VehicleLicensePrefixCode === undefined)
		// {
		// 	return modelResponse;
		// }
		else if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined)
		{
			return modelResponse;
		}
		else if(this.VehicleEngineNumber == null || this.VehicleEngineNumber === undefined)
		{
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
		}
	}

	//#endregion


	//#region  VALIDATE UPLOAD DOCUMENT

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Oto");

		if(this.listModelProductOtoGallery == null || this.listModelProductOtoGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductOtoGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductOtoGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductOtoGallery of this.listModelProductOtoGallery ?? [])
			{
				modelResponseProductOtoGallery = modelProductOtoGallery.validateAdd();

				if (modelResponseProductOtoGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductOtoGallery;
				}
				else
				{
					numberSize += modelProductOtoGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductOtoGallery?.filter(modelProductOtoGallery => modelProductOtoGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
					this.booleanDisable = true;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
						this.booleanDisable = false;
					}
				}
			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}


	validateUploadButtonDisable(): ResponseModel
	{
		let numberDocumentSuccess: number = 0;
		let numberDocumentFailed: number = 0;
		let booleanBreak: boolean = false;

		const modelResponse: ResponseModel = new ResponseModel();

		const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

		for(const modelSupportingDocument of arrayModelSupportingDocument)
		{
			const arrayDocument = this.listModelProductOtoGallery?.filter(modelProductOtoGallery => modelProductOtoGallery.Type === modelSupportingDocument.Type);

			if(arrayDocument?.length === 0)
			{
				if(booleanBreak === false)
				{
					booleanBreak = true;
					numberDocumentFailed = numberDocumentFailed + 1;
					return modelResponse;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
					return modelResponse;
				}
			}
			else
			{
				numberDocumentSuccess = numberDocumentSuccess + 1;
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		return modelResponse;
	}

	//#endregion
}

//#endregion
<div id="divBodyDashboard" class="DivBodyGeneral">
	<div id="divMainDashboard">
		<div class="DivBackgroundProduct">
			<img class="ImgIconBicycle">
			<p>Asuransi Sepeda</p>
		</div>

		<h2>Deskripsi</h2>
		<h4>Asuransi yang memberikan ganti rugi kepada Tertanggung terhadap kerugian atas dan / atau
			kerusakan pada sepeda dan / atau kepentingan yang dipertanggungkan.</h4>

		<h2>Harga Pertanggungan</h2>
		<div class="DivPriceValidation">
			<ul>
				<li>Harga Sepeda Maksimal s.d. Rp200.000.000</li>
				<li>Harga Sepeda Minimal Rp5.000.000</li>
			</ul>
		</div>

		<h2>Pilih Paket</h2>

		<div class="DivSelectPackage">
			<div class="DivWrapperSelectedPackage" (click)="openPackageListDialog()">
				<div class="ContainerLabelPackage">
					<ng-container *ngIf="this._modelProductBicycle.modelCoverage === undefined || this._modelProductBicycle.modelCoverage === null">
						<span>Paket</span>
						<label>Pilih Paket</label>
					</ng-container>

					<ng-container *ngIf="this._modelProductBicycle.modelCoverage !== undefined && this._modelProductBicycle.modelCoverage !== null">
						<div class="DivIconPackage">
							<img src="{{this._modelProductBicycle.modelCoverage.IconURL}}">
							<div class="DivContainerLabelPackage">
								<span>Paket</span>
								<label>{{this._modelProductBicycle.modelCoverage.Text}}</label>
							</div>
						</div>
					</ng-container>
				</div>
				<img src="../../../../../assets/inputs/sakina/input_arrow_bottom.svg">
			</div>
		</div>

		<ng-container *ngIf="this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER || this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD || this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM">
			<div class="DivContentPackage">
				<hr>
				<h4>Berikut adalah risiko yang dijamin:</h4>
				
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck">
						<label id="LabelForTitle">Kerugian total</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgCheck">
						<label id="LabelForTitle">Pencurian dengan kekerasan</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER , 'ImgCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD || this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM}">
						<label id="LabelForTitle">Bencana Alam</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER || this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD, 'ImgCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM}">
						<label id="LabelForTitle">Kerusuhan dan Huru-Hara</label>
				</div>
				<div class="DivImgForCheck DivSingleLine">
						<img class="ImgUnCheck" [ngClass]="{'ImgUnCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER || this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD, 'ImgCheck': this._modelProductBicycle.CoverageCode === _stringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM}">
						<label id="LabelForTitle">Terorisme dan Sabotase</label>
				</div>
			</div>
		</ng-container>

		<div class="DivWrapperListFormCard MarginLeft15 MarginRight15">
			<div class="DivCardInformation DivCardInformationForPackage">
				<div class="DivFirstCardPackage">
					<div class="DivWrapImgInformation">
						<img class="ImgInformation">
						<p>Informasi</p>
					</div>
					<label>Asuransi SEPEDA adalah produk asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia. Produk ini bukan merupakan produk dari PT. Bank Rakyat Indonesia (Persero), Tbk serta tidak termasuk dalam program penjaminan Pemerintah Republik Indonesia. PT. Bank Rakyat Indonesia (Persero) Tbk tidak bertanggung jawab atas kepesertaan asuransi yang diterbitkan oleh PT. BRI Asuransi Indonesia sehubungan dengan produk Asuransi SEPEDA. PT. Bank Rakyat Indonesia (Persero), Tbk bukan agen PT. BRI Asuransi Indonesia maupun broker dari nasabah PT. Bank Rakyat Indonesia (Persero), Tbk. PT. BRI Asuransi Indonesia adalah perusahaan asuransi yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</label>
				</div>
			</div>
		</div>

		<input type="button" class="ButtonNext" value="Lanjutkan" (click)="goToPremiumSimulation()" [disabled]="_booleanButtonDisable" >
	</div>
</div>


<!-- START MODAL -->

<div class="DivModal" id="divModalBottomListItem">
	<label class="LabelPackageList">Paket</label>
	<form>
		<ng-container *ngFor="let modalItemBottom of _arrayDisplayed; let numberIndex = index">
			<div class="DivListItemForDropdown">
				<div class="DivContainerIconPackage">
					<input type="button" value="{{ modalItemBottom }}" name="modalDialogBottom"
						id="modalBottomItem{{numberIndex}}" (click)="closeModalBottomList(modalItemBottom)"
						[value]="modalItemBottom.PackageCode">
					<img src="{{modalItemBottom.IconURL}}">
					<label class="labelForPackage" for="modalBottomItem{{numberIndex}}">{{ modalItemBottom.Text }}</label>
				</div>
			</div>
			<hr>
		</ng-container>
	</form>
</div>

<!-- END MODAL -->
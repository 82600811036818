//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AsmikdamageofbusinessplacepackageComponent } from "./components/product/asmikdamageofbusinessplace/asmikdamageofbusinessplacepackage/asmikdamageofbusinessplacepackage.component";
import { AsmikmyhousepackageComponent } from "./components/product/asmikmyhouse/asmikmyhousepackage/asmikmyhousepackage.component";
import { AsmikmymotorcyclepackageComponent } from "./components/product/asmikmymotorcycle/asmikmymotorcyclepackage/asmikmymotorcyclepackage.component";
import { AsripackageComponent } from "./components/product/asri/asripackage/asripackage.component";
import { BicyclepackageComponent } from "./components/product/bicycle/bicyclepackage/bicyclepackage.component";
import { OtopackageComponent } from "./components/product/oto/otopackage/otopackage.component";
import { StringConstant } from "./constants/string.constant";
import { PublicGuardService } from "./services/guards/public.guard.service";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "product",
		loadChildren:() => import("./components/product/product.module").then(pageModule => pageModule.ProductModule),
	},
	{
		path: "errorconection",
		loadChildren: () => import("./components/product/shared/errorconection/errorconection.module").then(pageModule => pageModule.ErrorconectionModule),
	},
	{
		path: "internalservererror",
		loadChildren: () => import("./components/product/shared/errorinternalserver/errorinternalserver.module").then(pageModule => pageModule.ErrorinternalserverModule),
	},
	{
		path: "sessionexpired",
		loadChildren: () => import("./components/product/shared/errorsessionexpired/errorsessionexpired.module").then(pageModule => pageModule.ErrorsessionexpiredModule),
	},
	{
		path: "purchasingsuccess",
		loadChildren: () => import("./components/product/shared/purchasingsuccess/purchasingsuccess.module").then(pageModule => pageModule.PurchasingsuccessModule),
	},
	{
		path: "asri/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "asri/asripackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "asri/asripackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AsripackageComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "oto/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "oto/otopackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "oto/otopackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: OtopackageComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "sepeda/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "bicycle/bicyclepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "bicycle/bicyclepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: BicyclepackageComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "ktu/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "asmikdamageofbusinessplace/asmikdamageofbusinessplacepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "asmikdamageofbusinessplace/asmikdamageofbusinessplacepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AsmikdamageofbusinessplacepackageComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "rumahku/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "asmikmyhouse/asmikmyhousepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "asmikmyhouse/asmikmyhousepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AsmikmyhousepackageComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "motorku/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		redirectTo: "asmikmymotorcycle/asmikmymotorcyclepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	},
	{
		path: "asmikmymotorcycle/asmikmymotorcyclepackage/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AsmikmymotorcyclepackageComponent,
		canActivate: [PublicGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports: [RouterModule.forRoot(arrayRoute)],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion

//#region IMPORT

import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { PartialObserver } from "rxjs";
import { SessionService } from "src/app/services/session.service";
import { HandshakeModel } from "../../models/handshake.model";
import { StringConstant } from "../../constants/string.constant";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CLASS

export class BaseAuthourizePackageComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;
	public _booleanButtonBack: boolean | null;
	public _booleanValidation: boolean | null;
	protected _routeActivated: ActivatedRoute;

	protected _location: Location;
	private _modelHandshakeSignIn: HandshakeModel | null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._routeActivated = routeActivated;
		this._location = location;
		this._booleanReadOnly = true;
		this._booleanButtonBack = false;
		this._booleanValidation = false;

		this._modelHandshakeSignIn = new HandshakeModel();

		const observerPartialModelHandshake: PartialObserver<HandshakeModel> =
		{
			next: (modelHandshake: HandshakeModel) => { this._modelHandshakeSignIn = modelHandshake; },
			error: () => { },
			complete: () => { }
		};
		this._serviceSession._modelHandshakeSignIn.subscribe(observerPartialModelHandshake);
	}

	//#endregion


	//#region GETTER

	protected getModelHandshakeSignIn(): HandshakeModel | null
	{
		if (this.validateModelHandshake(this._modelHandshakeSignIn))
		{
			return this._modelHandshakeSignIn;
		}
		else
		{
			this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				return this._modelHandshakeSignIn;
			}
			else
			{
				return null;
			}
		}
	}


	//#region GETTER

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	//#endregion

	//#endregion


	//#region  VALIDATION

	private validateModelHandshake(modelHandshake?: HandshakeModel | null): boolean
	{
		if (modelHandshake == null || modelHandshake === undefined)
		{
			return false;
		}
		else
		{
			if (modelHandshake.Token != null && modelHandshake.Token.validateEmpty())
			{
				return true;
			}
			else
			{
				return false;
			}
		}
	}

	//#endregion


	//#region CHECK

	protected checkInsert(): boolean
	{
		return this._router.url.indexOf("insert") > -1;
	}

	//#endregion


	//#region NAVIGATION

	public goToBack(): void
	{
		this._location.back();
	}

	//#endregion
}

//#endregion
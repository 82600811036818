//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StringConstant } from "../../constants/string.constant";
import { AuthorizedGuardService } from "../../services/guards/authorized.guard.service";
import { CameracaptureComponent } from "./shared/cameracapture/cameracapture.component";

//#endregion


//#region ROUTE

const arrayRoutes: Routes =
	[
		{
			path: "camera",
			component: CameracaptureComponent,
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "policyholder",
			loadChildren: () => import("./shared/policyholder/policyholder.module").then(pageModule => pageModule.PolicyholderModule),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "supportingdocument",
			loadChildren: () => import("./shared/supportingdocument/supportingdocument.module").then(pageModule => pageModule.SupportingdocumentModule),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "premiumresult",
			loadChildren: () => import("./shared/premiumresult/premiumresult.module").then(pageModule => pageModule.PremiumresultModule),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "discount",
			loadChildren: () => import("./shared/productdiscount/productdiscount.module").then(pageModule => pageModule.ProductdiscountModules),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "purchasingdetail/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
			loadChildren: () => import("./shared/purchasingdetail/purchasingdetail.module").then(pageModule => pageModule.PurchasingdetailModule),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "purchasingsummary/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
			loadChildren: () => import("./shared/purchasingsummary/purchasingsummary.module").then(pageModule => pageModule.PurchasingsummaryModule),
			canActivate: [AuthorizedGuardService]
		},
		{
			path: "asri",
			loadChildren: () => import("./asri/asri.module").then(pageModule => pageModule.AsriModule),
		},
		{
			path: "oto",
			loadChildren: () => import("./oto/oto.module").then(pageModule => pageModule.OtoModule),
		},
		{
			path: "bicycle",
			loadChildren: () => import("./bicycle/bicycle.module").then(pageModule => pageModule.BicycleModule),
		},
		{
			path: "asmikdamageofbusinessplace",
			loadChildren: () => import("./asmikdamageofbusinessplace/asmikdamageofbusinessplace.module").then(pageModule => pageModule.AsmikdamageofbusinessplaceModule),
		},
		{
			path: "asmikmyhouse",
			loadChildren: () => import("./asmikmyhouse/asmikmyhouse.module").then(pageModule => pageModule.AsmikmyhouseModule),
		},
		{
			path: "asmikmymotorcycle",
			loadChildren: () => import("./asmikmymotorcycle/asmikmymotorcycle.module").then(pageModule => pageModule.AsmikmymotorcycleModule),
		},
	];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports: [RouterModule.forChild(arrayRoutes)],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class ProductRoutingModule
{

}

//#endregion

//#region IMPORT

import { ENUM_GENERAL_STATE } from "src/app/constants/enum.constant";
import { ProductAsriModel } from "../productasri.model";
import { ProductOtoModel } from "../productoto.model";
import { ProductBicycleModel } from "../productbicycle.model";
import { ProductGeneralModel } from "../productgeneral.model";
import { DiscountModel } from "../discount.model";
import { ProductDamageOfBusinessPlaceModel } from "../productdamageofbusinessplace.model";
import { ProductMyHouseModel } from "../productmyhouse.model";
import { ProductMyMotorcycleModel } from "../productmymotorcycle.model";
import { GeneralModel } from "./general.model";

//#endregion


//#region CLASS

export class BaseModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for Base model, default class for model. It will record when it created and updated, also store the general state.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.				Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	CreatedBy?: number;
	CreatedOn?: Date;
	UpdatedBy?: number;
	UpdatedOn?: Date;
	State?: ENUM_GENERAL_STATE;
	ProductCode?: string;

	DiscountAmount?: number;
	DiscountCode?: string;
	PremiumAmount?: number;
	PremiumTotalAmount?: number;
	PremiumTotalAmountWithDiscount?: number;
	AdministrationAmount?: number;

	modelProductGeneral?: ProductGeneralModel;
	modelDiscount?: DiscountModel;
	modelProductAsri?: ProductAsriModel;
	modelProductOto?: ProductOtoModel;
	modelProductBicycle?: ProductBicycleModel;
	modelProductDamageofbusinessplace?: ProductDamageOfBusinessPlaceModel;
	modelProductMyHouse?: ProductMyHouseModel;
	modelProductMyMotorcycle?: ProductMyMotorcycleModel;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region CLEAR

	clearFootprint(): void
	{
		this.CreatedBy = undefined;
		this.UpdatedBy = undefined;
	}

	//#endregion
}

//#endregion

// #region  IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { HandshakeModel } from "src/app/models/handshake.model";
import { PremiumModel } from "src/app/models/premium.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ProductMyHouseModel } from "src/app/models/productmyhouse.model";
import { ResponseModel } from "src/app/models/response.model";
import { ProductMyhouseService } from "src/app/services/productmyhouse.service";
import { NumberConstant } from "../../../../constants/number.constant";
import { ResponseCodeConstant } from "../../../../constants/responsecode.constant";
import { BaseModel } from "../../../../models/bases/base.model";
import { UserModel } from "../../../../models/user.model";
import { UserSessionModel } from "../../../../models/usersession.model";
import { HandshakeService } from "../../../../services/handshake.service";
import { SessionService } from "../../../../services/session.service";
import { BaseAuthourizeDetailComponent } from "../../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-asmikmyhousepackage",
	templateUrl: "./asmikmyhousepackage.component.html",
	styleUrls: ["./asmikmyhousepackage.component.sass"]
})

//#endregion


//#region  CLASS

export class AsmikmyhousepackageComponent extends BaseAuthourizeDetailComponent
{
	//#region  DECLARATION

	public _modelBase: BaseModel;
	public _modelHandshake: HandshakeModel;
	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductGeneralRequest: ProductGeneralModel;
	public _modelProductGeneralResponse: ProductGeneralModel;
	public _modelProductMyhouse: ProductMyHouseModel;

	public _stringClassForTitle?: any;
	public _stringConstant = StringConstant;

	public _booleanGender: boolean | null;
	public _booleanButtonDisable: boolean | null;

	public _numberConstant = NumberConstant;

	public _arrayDisplayed: Array<any> = [];

	//#endregion


	//#region  CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, routeActivated: ActivatedRoute , location: Location, private _serviceHandshake: HandshakeService, private _serviceProductMyHouse: ProductMyhouseService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelBase = new BaseModel();
		this._modelHandshake = new HandshakeModel();
		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductGeneralRequest = new ProductGeneralModel();
		this._modelProductGeneralResponse = new ProductGeneralModel();
		this._modelProductMyhouse = new ProductMyHouseModel();

		this._booleanGender = null;
		this._booleanButtonDisable = true;
	}

	//#endregion


	//#region  INTITALIZATION

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelHandshake.Token = stringToken;
			this.callSelectHandshakeByTokenForNewDeliverySystem();
		}
		else
		{
		}

		this._modelProductGeneralRequest.cleanForProductMyHouse();
		this._modelProductGeneralRequest.setProductASMIKMyHouse();
	}

	//#endregion


	//#region  NAVIGATION

	goToPolicyHolder()
	{
		this._modelBase.ProductCode = StringConstant.STRING_VALUE_PRODUCT_MYHOUSE;
		this._modelProductMyhouse.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYHOUSE;

		if(this._modelBase.ProductCode !== undefined)
		{
			// const modelCoverage: CoverageModel = new CoverageModel();
			// modelCoverage.IconURL = "../../../../../assets/icons/sakina/icon_rumahku_new.svg";
			// modelCoverage.PackageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYHOUSE;

			// this._modelProductMyhouse.modelCoverage = modelCoverage;

			this._serviceProductMyHouse.setProductCode(this._modelProductMyhouse);
			this._modelBase.modelProductMyHouse = this._modelProductMyhouse;
			this._serviceSession.setModelBase(this._modelBase);

			this._booleanButtonDisable = false;
		}

		this._router.navigate(["product", "policyholder"]);
	}

	//#endregion


	//#region WEBSERVICE

	callSelectHandshakeByTokenForNewDeliverySystem(): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const componentCurrent: AsmikmyhousepackageComponent = this;

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.Token = this._modelHandshake.Token;

		this._serviceHandshake.selectHandshakeByTokenForNewDeliverySystem
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								componentCurrent._modelHandshake = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);

								if(modelUserResponse.Token != null && modelUserResponse.Token !== undefined && modelUserResponse.Token !== "" )
								{
									const modelUserSession = new UserSessionModel();
									modelUserSession.UserToken = modelUserResponse.Token;
									componentCurrent._serviceSession.setUserSession(modelUserSession);
									componentCurrent.callCalculatePremiumForCustomer(componentCurrent);
								}
								else
								{
									componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
								}
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHandshakeByTokenForNewDeliverySystem(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
					componentCurrent._router.navigate(["sessionexpired"]);
				});
			}
		}, this._modelHandshake);
	}

	callCalculatePremiumForCustomer(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		let modelProductGeneral: ProductGeneralModel = new ProductGeneralModel();
		const modelProductMyHouse: ProductMyHouseModel = new ProductMyHouseModel();
		modelProductMyHouse.setCoverageCodeYearly();

		componentCurrent._modelProductGeneralRequest.modelProductMyHouse = modelProductMyHouse;

		modelProductGeneral = componentCurrent._modelProductGeneralRequest;

		this._serviceProductMyHouse.calculatePremiumForNewDeliverySystem
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelPremiumTemp = JSON.parse(modelResponse.Data);

						let modelPremium: PremiumModel = new PremiumModel();

						componentCurrent._modelProductGeneralRequest.getClearCalculate();

						for (const modelPremiumTemp of arrayModelPremiumTemp)
						{
							modelPremium = new PremiumModel();
							modelPremium.setModelFromObject(modelPremiumTemp);

							if(componentCurrent._modelProductGeneralRequest.AdministrationAmount !== null && componentCurrent._modelProductGeneralRequest.AdministrationAmount !== undefined)
							{
								if(modelPremium.AdministrationAmount !== null && modelPremium.AdministrationAmount !== undefined)
								{
									componentCurrent._modelProductGeneralRequest.AdministrationAmount = modelPremium.AdministrationAmount;
								}
								else
								{

								}
							}
							else
							{

							}

							if(componentCurrent._modelProductGeneralRequest.StampAmount !== null && componentCurrent._modelProductGeneralRequest.StampAmount !== undefined)
							{
								if(modelPremium.StampAmount !== null && modelPremium.StampAmount !== undefined)
								{
									componentCurrent._modelProductGeneralRequest.StampAmount += modelPremium.StampAmount;
								}
								else
								{

								}
							}
							else
							{

							}

							if(componentCurrent._modelProductGeneralRequest.PremiumAmount !== null && componentCurrent._modelProductGeneralRequest.PremiumAmount !== undefined)
							{
								if(modelPremium.PremiumBasicAmount !== null && modelPremium.PremiumBasicAmount !== undefined)
								{
									componentCurrent._modelProductGeneralRequest.PremiumAmount += modelPremium.PremiumBasicAmount;
								}
								else
								{

								}
							}
							else
							{

							}

							if(componentCurrent._modelProductGeneralRequest.DiscountAmount !== null && componentCurrent._modelProductGeneralRequest.DiscountAmount !== undefined)
							{
								if(modelPremium.DiscountAmount !== null && modelPremium.DiscountAmount !== undefined)
								{
									componentCurrent._modelProductGeneralRequest.DiscountAmount += modelPremium.DiscountAmount;
								}
								else
								{

								}
							}
							else
							{

							}
						}

						componentCurrent._modelProductGeneralRequest.premiumTotalAmount();
						componentCurrent._modelProductGeneralResponse.setModelFromString(modelResponse.Data);

						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						componentCurrent._modelBase.modelProductGeneral = componentCurrent._modelProductGeneralRequest;
						componentCurrent._modelBase.modelProductGeneral.PolicyholderPotentialAmount = componentCurrent._modelProductGeneralRequest.PremiumTotalAmount;
						componentCurrent._modelBase.modelProductDamageofbusinessplace = componentCurrent._modelProductGeneral.modelProductDamageofbusinessplace;
						componentCurrent._serviceSession.setModelBase(componentCurrent._modelBase);
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callCalculatePremiumForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelProductGeneral);
	}

	//#endregion
}

//#endregion

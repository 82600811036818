//#region IMPORT

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProductRoutingModule } from "./product-routing.module";
import { ErrorconectionComponent } from "./shared/errorconection/errorconection.component";
import { ErrorinternalserverComponent } from "./shared/errorinternalserver/errorinternalserver.component";
import { ErrorsessionexpiredComponent } from "./shared/errorsessionexpired/errorsessionexpired.component";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ErrorsessionexpiredComponent,
			ErrorconectionComponent,
			ErrorinternalserverComponent,
		],
		imports:
		[
			CommonModule,
			ProductRoutingModule,
		]
	}
)

//#endregion


//#region CLASS

export class ProductModule
{

}

//#endregion

//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PRODUCT_LOCATIONINFORMATION, ENUM_PRODUCT_OWNERSHIPINFORMATION, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { BeneficiariesMyHouseModel } from "./beneficiariesmyhouse.model";
import { ProductDamageOfBusinessPlaceGalleryModel } from "./productdamageofbusinessplacegallery.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductMyHouseModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asmik my house.
		Author: Eka Saputra.
		Created on : Wednesday, 01 June 2022. 				Updated on : Wednesday, 01 June 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;

	SumInsuredDefault?: number;

	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;
	ReferenceNumber?: string;
	// PremiumTotalAmount?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryCode?: string;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceCode?: string;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityCode?: string;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalCode?: string;
	BuildingPostalName?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	HouseFireCompensation?: number;
	ReliefCompensation?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: string;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	OwnershipInformation?: ENUM_PRODUCT_OWNERSHIPINFORMATION;
	LocationInformation?: ENUM_PRODUCT_LOCATIONINFORMATION;

	modelProductGeneral?: ProductGeneralModel;
	listModelProductMyHouseGallery?: Array<ProductDamageOfBusinessPlaceGalleryModel>;
	listModelBeneficiariesMyHouse?: Array<BeneficiariesMyHouseModel>;

	/* Attribute - END */


	//#region CONSTRUCTOR


	//#endregion

	constructor()
	{
		super();
		this.listModelProductMyHouseGallery = [];
		this.listModelBeneficiariesMyHouse = [];
	}

	//#region VALIDATION

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductMyHouseGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpdateInsuredProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Rumahku";

		if (this.SumInsuredDefault == null || this.SumInsuredDefault === undefined)
		{
			modelResponse.MessageContent = "Nilai santunan tidak boleh kosong.";
		}
		else if (this.BuildingPostalCode == null || this.BuildingPostalCode === undefined || this.BuildingPostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong.";
		}
		else if (this.BuildingPostalCode.length < RuleConstant.NUMBER_POSTALCODE_LENGTH || this.BuildingPostalCode.length > RuleConstant.NUMBER_POSTALCODE_LENGTH)
		{
			modelResponse.MessageContent = "Kode pos harus "+RuleConstant.NUMBER_POSTALCODE_LENGTH+" digit.";
		}
		else if (this.BuildingPostalName == null || this.BuildingPostalName === undefined || this.BuildingPostalName === "")
		{
			modelResponse.MessageContent = "Kode pos tidak ditemukan.";
		}
		else if (this.BuildingAddress == null || this.BuildingAddress === undefined || this.BuildingAddress === "")
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong.";
		}
		else if (this.OwnershipInformation == null || this.OwnershipInformation === undefined)
		{
			modelResponse.MessageContent = "Informasi kepemilikan tidak boleh kosong.";
		}
		else if (this.LocationInformation == null || this.LocationInformation === undefined)
		{
			modelResponse.MessageContent = "Informasi lokasi tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateForBeneficiariesDamageOfBusinessPlace(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Rumahku";
		if(this.listModelBeneficiariesMyHouse == null || this.listModelBeneficiariesMyHouse === undefined)
		{
			modelResponse.MessageContent = "Waris tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		this.listModelBeneficiariesMyHouse?.forEach(modelBeneficiariesMyHouse =>
		{
			modelBeneficiariesMyHouse.validateForBeneficiariesDamageOfBusinessPlace();
		});

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Rumahku");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Rumahku");

		if(this.listModelProductMyHouseGallery == null || this.listModelProductMyHouseGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductMyHouseGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductMyHouseGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductMyHouseGallery of this.listModelProductMyHouseGallery ?? [])
			{
				modelResponseProductMyHouseGallery = modelProductMyHouseGallery.validateAdd();

				if (modelResponseProductMyHouseGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductMyHouseGallery;
				}
				else
				{
					numberSize += modelProductMyHouseGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTDAMAGEOFBUSINESS_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTDAMAGEOFBUSINESS_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductMyHouseGallery?.filter(modelProductProductMyHouseGallery => modelProductProductMyHouseGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setCoverageCodeYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYHOUSE;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: 0,
				Name : "Foto Bangunan"
			},
		];

		return arrayModelGalleryModel;
	}

	// setForUpdateInsuredDamageOfBusinessPlaceForm(modelProductDamageOfBusinessPlace: ProductDamageOfBusinessPlaceModel): void
	// {
	// 	this.BuildingAddress = modelProductDamageOfBusinessPlace.BuildingAddress;
	// 	this.BuildingPostalCode = modelProductDamageOfBusinessPlace.BuildingPostalCode;
	// 	this.LocationInformation = modelProductDamageOfBusinessPlace.LocationInformation;
	// 	this.OwnershipInformation = modelProductDamageOfBusinessPlace.OwnershipInformation;
	// 	this.SumInsuredDefault = modelProductDamageOfBusinessPlace.SumInsuredDefault;
	// 	this.Token = modelProductDamageOfBusinessPlace.Token;

	// 	if(modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace != null || modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace !== undefined)
	// 	{
	// 		modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace.forEach(modelBeneficiariesDamageOfBusinessPlace =>
	// 		{
	// 			this.listModelBeneficiariesDamageOfBusinessPlace?.push(modelBeneficiariesDamageOfBusinessPlace);
	// 		});
	// 	}
	// }

	//#endregion


	//#region SETTER

	setMyHouseYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYHOUSE;
	}

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;

		if(this.listModelProductMyHouseGallery == null || this.listModelProductMyHouseGallery === undefined || this.listModelProductMyHouseGallery.length === 0 )
		{
			numberProgress = 20;
		}
		else if((this.listModelProductMyHouseGallery != null || this.listModelProductMyHouseGallery !== undefined) && this.listModelProductMyHouseGallery.length > 0)
		{
			numberProgress = 50;
		}
		else
		{
			numberProgress = 80;
		}

		return numberProgress;
	}

	//#endregion

	//#region CLEAN

	cleanForPostalCode(): void
	{
		this.BuildingCityName = undefined;
		this.BuildingCountryName = undefined;
		this.BuildingPostalName = undefined;
		this.BuildingProvinceName = undefined;
		this.BuildingPostalID = undefined;
	}

	//#endregion
}
//#endregion

// #region  IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { StringConstant } from "src/app/constants/string.constant";
import { ResponseModel } from "src/app/models/response.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { NumberConstant } from "../../../../constants/number.constant";
import { CryptographyFunction } from "../../../../functions/cryptography.function";
import { CoverageModel } from "../../../../models/coverage.model";
import { HandshakeModel } from "../../../../models/handshake.model";
import { ProductBicycleModel } from "../../../../models/productbicycle.model";
import { UserModel } from "../../../../models/user.model";
import { UserSessionModel } from "../../../../models/usersession.model";
import { HandshakeService } from "../../../../services/handshake.service";
import { ProductbicycleService } from "../../../../services/productbicycle.service";
import { SessionService } from "../../../../services/session.service";
import { BaseAuthourizePackageComponent } from "../../../bases/baseauthourizepackage.component";
import { BaseModel } from "../../../../models/bases/base.model";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-bicyclepackage",
		templateUrl: "./bicyclepackage.component.html",
		styleUrls: ["./bicyclepackage.component.sass"]
	}
)

//#endregion


//#region  CLASS

export class BicyclepackageComponent extends BaseAuthourizePackageComponent
{
	//#region  DECLARATION

	public _modelBase: BaseModel;
	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductBicycle: ProductBicycleModel;
	public _modelHandshake: HandshakeModel;

	public _booleanGender: boolean | null;
	public _booleanButtonDisable: boolean | null;

	public _stringConstant = StringConstant;

	public _numberConstant = NumberConstant;
	public _numberPackage = null;
	public _iconPackage = null;

	public _arrayDisplayed: Array<any> = [];
	public _arrayPackage: Array<CoverageModel> =
		[
			{Text: "Silver", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER, IconURL: "../../../../../assets/icons/sakina/icon_package_silver.svg"},
			{Text: "Gold", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD, IconURL: "../../../../../assets/icons/sakina/icon_package_gold.svg"},
			{Text: "Platinum", PackageCode: this._stringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM, IconURL: "../../../../../assets/icons/sakina/icon_package_platinum.svg"},
		];

	private _functionCryptography: CryptographyFunction;
	private _elementHTMLDivModal: HTMLElement | null;

	//#endregion


	//#region  CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, routeActivated: ActivatedRoute , location: Location, private _serviceProductBicycle: ProductbicycleService, private _serviceHandshake: HandshakeService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelBase = new BaseModel();
		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductBicycle = new ProductBicycleModel();
		this._modelHandshake = new HandshakeModel();
		this._booleanGender = null;
		this._booleanButtonDisable = true;

		this._functionCryptography = new CryptographyFunction();
		this._elementHTMLDivModal = document.getElementById("divModalBottomListItem");
	}

	//#endregion


	//#region  INTITALIZATION

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelHandshake.Token = stringToken;
			this.callSelectHandshakeByToken();
		}
		else
		{
		}

		this._modelProductGeneral.cleanProductAsmik();
	}

	//#endregion


	//#region  NAVIGATION

	goToPremiumSimulation()
	{
		this._booleanButtonDisable = true;
		this._router.navigate(["product", "bicycle", "bicyclepremiumsimulation"]);
	}

	goToPage()
	{
		this._router.navigate(["product", "bicycle", "bicyclepage"]);
	}

	//#endregion


	//#region FUNCTION

	closeModalBottomList(modelCoverage: CoverageModel)
	{
		this._modelBase.ProductCode = StringConstant.STRING_VALUE_PRODUCT_BICYCLE;

		this._elementHTMLDivModal = document.getElementById("divModalBottomListItem");

		if(modelCoverage !== undefined)
		{
			if(modelCoverage.PackageCode !== undefined)
			{
				this._modelProductBicycle.CoverageCode = modelCoverage.PackageCode;
				this._modelProductBicycle.modelCoverage = modelCoverage;

				this._serviceProductBicycle.setProductCode(this._modelProductBicycle);
				this._modelBase.modelProductBicycle = this._modelProductBicycle;
				this._serviceSession.setModelBase(this._modelBase);

				this._booleanButtonDisable = false;
			}
		}

		if(this._elementHTMLDivModal)
		{
			this._functionUserInterface.hideCurtainLoading();
			this._elementHTMLDivModal.style.bottom = "-1000px";

		}
		else
		{

		}
	}

	openPackageListDialog()
	{
		this._elementHTMLDivModal = document.getElementById("divModalBottomListItem");

		this._arrayDisplayed = [];
		for(const stringItem of this._arrayPackage)
		{
			this._arrayDisplayed.push(stringItem);
		}
		if(this._elementHTMLDivModal)
		{
			this._functionUserInterface.showCurtainLoadingForDropdown();
			this._elementHTMLDivModal.style.bottom = "0";
		}
	}

	//#endregion


	//#region WEBSERVICE

	callSelectHandshakeByToken(): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const componentCurrent: BicyclepackageComponent = this;

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.Token = this._modelHandshake.Token;

		this._serviceHandshake.selectHandshakeByTokenForNewDeliverySystem
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelUserResponse: UserModel = new UserModel();
					modelUserResponse.setModelFromString(modelResponse.Data);

					if (modelUserResponse != null)
					{
						componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

						if (modelUserResponse.modelHandshake !== undefined)
						{
							componentCurrent._modelHandshake = new HandshakeModel();
							modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
							componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
						}

						if(modelUserResponse.Token != null && modelUserResponse.Token !== undefined && modelUserResponse.Token !== "" )
						{
							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;
							componentCurrent._serviceSession.setUserSession(modelUserSession);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHandshakeByToken(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
				{
					componentCurrent.signOut();
					componentCurrent._router.navigate(["sessionexpired"]);
				});
			}
		}, this._modelHandshake);
	}


	private callSelectHandshakeForBRIMOO(): Observable<boolean>
	{
		const componentCurrent: BicyclepackageComponent = this;
		const modelHandshakeRSA: HandshakeModel = this._functionCryptography.generateKey();

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.RequestKey = modelHandshakeRSA.RequestKey;
		modelHandshake.removeHeaderForHandshake();

		return this._serviceHandshake.selectHandshakeByTokenForNewDeliverySystemOO
		(
			{
				success(modelResponse, stringAuthorizedKey): boolean
				{
					if (modelResponse.Data !== undefined && modelHandshakeRSA.ResponseKey !== undefined)
					{
						const stringModelHandshake: string = componentCurrent._functionCryptography.decryptAsymmetric(modelResponse.Data, modelHandshakeRSA.ResponseKey);
						const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
						modelHandshakeResponse.setModelFromString(stringModelHandshake);
						modelHandshakeResponse.Token = stringAuthorizedKey;
						componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);

						return true;
					}
					else
					{
						return false;
					}
				},
				fail(modelResponse): Observable<boolean>
				{
					componentCurrent._router.navigate(["product", "internalservererror"]);

					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { });

					return componentCurrent.callSelectHandshakeForBRIMOO();
				},
				failNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					return false;
				},
				signOut(modelResponse): Observable<any>
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					// eslint-disable-next-line @typescript-eslint/no-unsafe-return
					return of(false);
				},
				signOutNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					return false;
				}
			}, modelHandshake
		);
	}

	//#endregion



}

//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PRODUCT_LOCATIONINFORMATION, ENUM_PRODUCT_OWNERSHIPINFORMATION, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { BeneficiariesDamageOfBusinessPlaceModel } from "./beneficiariesdamageofbusinessplace.model";
import { ProductDamageOfBusinessPlaceGalleryModel } from "./productdamageofbusinessplacegallery.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductDamageOfBusinessPlaceModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asmik ktu.
		Author: Eka Saputra.
		Created on : Friday, 27 May 2022. 				Updated on : Friday, 27 May 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	CompensationValue?: number;

	SumInsuredDefault?: number;

	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;
	ReferenceNumber?: string;
	// PremiumTotalAmount?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryCode?: string;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceCode?: string;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityCode?: string;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalCode?: string;
	BuildingPostalName?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	BuildingPrice?: number;
	InteriorPrice?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: string;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	OwnershipInformation?: ENUM_PRODUCT_OWNERSHIPINFORMATION;
	LocationInformation?: ENUM_PRODUCT_LOCATIONINFORMATION;

	modelProductGeneral?: ProductGeneralModel;
	listModelProductDamageOfBusinessPlaceGallery?: Array<ProductDamageOfBusinessPlaceGalleryModel>;
	listModelBeneficiariesDamageOfBusinessPlace?: Array<BeneficiariesDamageOfBusinessPlaceModel>;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelProductDamageOfBusinessPlaceGallery = [];
	}

	//#endregion


	//#region SETTER

	setCoverageCodeYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_DAMAGEOFBUSINESSPLACE;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: 0,
				Name : "Foto Bangunan"
			},
		];

		return arrayModelGalleryModel;
	}

	setForUpdateInsuredDamageOfBusinessPlaceForm(modelProductDamageOfBusinessPlace: ProductDamageOfBusinessPlaceModel): void
	{
		this.BuildingAddress = modelProductDamageOfBusinessPlace.BuildingAddress;
		this.BuildingPostalCode = modelProductDamageOfBusinessPlace.BuildingPostalCode;
		this.LocationInformation = modelProductDamageOfBusinessPlace.LocationInformation;
		this.OwnershipInformation = modelProductDamageOfBusinessPlace.OwnershipInformation;
		this.SumInsuredDefault = modelProductDamageOfBusinessPlace.SumInsuredDefault;
		this.Token = modelProductDamageOfBusinessPlace.Token;

		if(modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace != null || modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace !== undefined)
		{
			modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace.forEach(modelBeneficiariesDamageOfBusinessPlace =>
			{
				this.listModelBeneficiariesDamageOfBusinessPlace?.push(modelBeneficiariesDamageOfBusinessPlace);
			});
		}
	}

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;

		if(this.listModelProductDamageOfBusinessPlaceGallery == null || this.listModelProductDamageOfBusinessPlaceGallery === undefined || this.listModelProductDamageOfBusinessPlaceGallery.length === 0)
		{
			numberProgress = 20;
		}
		else if((this.listModelProductDamageOfBusinessPlaceGallery != null || this.listModelProductDamageOfBusinessPlaceGallery !== undefined) && this.listModelProductDamageOfBusinessPlaceGallery.length > 0)
		{
			numberProgress = 50;
		}
		else
		{
			numberProgress = 80;
		}

		return numberProgress;
	}

	//#endregion


	//#region CLEAN

	cleanForPostalCode(): void
	{
		this.BuildingCityName = undefined;
		this.BuildingCountryName = undefined;
		this.BuildingPostalName = undefined;
		this.BuildingProvinceName = undefined;
		this.BuildingPostalID = undefined;
	}

	//#endregion


	//#region VALIDATE

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk KTU");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk KTU");

		if(this.listModelProductDamageOfBusinessPlaceGallery == null || this.listModelProductDamageOfBusinessPlaceGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductDamageOfBusinessPlaceGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductDamageofbusinessplaceGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductDamageofbusinessplaceGallery of this.listModelProductDamageOfBusinessPlaceGallery ?? [])
			{
				modelResponseProductDamageofbusinessplaceGallery = modelProductDamageofbusinessplaceGallery.validateAdd();

				if (modelResponseProductDamageofbusinessplaceGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductDamageofbusinessplaceGallery;
				}
				else
				{
					numberSize += modelProductDamageofbusinessplaceGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTBICYCLE_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTBICYCLE_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductDamageOfBusinessPlaceGallery?.filter(modelProductDamageofbusinessplaceGallery => modelProductDamageofbusinessplaceGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	//#endregion


}
//#endregion